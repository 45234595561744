import React, { useEffect } from 'react'
import './testimonial.css'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md'
import Card from './Card'
import { useState } from 'react'
import { IconsGradient } from '../UI/Gradient'
import { FaQuoteRight } from "react-icons/fa";
import testImg from '../../images/testimonial.svg';

const TestimonialCards = ({ testimonials, currentCard, handleMouseEnter,handleMouseLeave ,handleTouchEnd,handleTouchStart }) => {
  const style = {
    transform: `translateX(-${338 * currentCard}px)`
  }
  return (
    <div className="testimonial__right" style={style}>
      {testimonials.map((ele, current) => <Card key={current} data={ele} handleMouseEnter={handleMouseEnter}  handleTouchEnd={handleTouchEnd} 
      handleTouchStart={handleTouchStart}
          handleMouseLeave={handleMouseLeave}  current={current} currentCard={currentCard} />)}
      
    </div>
  )
}

const Testimonial = () => {
  const [currentCard, setCurrentCard] = useState(0)
  const [autoPlay, setAutoPlay] = useState(true);
  let testimonials = [
    {
      name: 'CFO - A leading fintech company      ',
      desp: "I want to acknowledge your team's understanding and expertise in their efforts to meet our project goals. This is what we wanted to achieve. It has also allowed us to discover the expertise of the NimbusBytes team and the value you bring to your clients. I would like to give them a 5 rating for their exceptional work ethic and quality.      ",
      img: testImg
    },
    {
      name: 'CTO - A leading E-commerce solution provider      ',
      desp: "We have been very impressed with NimbusBytes's flexibility and dedication to our project. The team collaboration and round-the-clock availability were exceptional, considering the differences in geographies and time. They helped us mitigate the chances of delays and made the working experience seamless.      ",
      img: testImg
    },
    {
      name: 'VP - A Saas provider of a leading logistics company',
      desp: "Your understanding, knowledge of cloud-managed service, and proactiveness in helping us complete our project on time were commendable. I recommend Nimbusbytes to future clients for their cloud computing services requirements.",
      img: testImg
    },
    {
      name: 'CTO - A leading software solutions provider      ',
      desp: "I acknowledge your understanding and expertise in software development services. Thank you for meeting our expectations and timeline while ensuring a seamless process. Your efforts and experience helped us reach the delivery stage in a pre-defined time. Highly recommended!      ",
      img: testImg
    },
    {
      name: 'CFO - A leading fintech company      ',
      desp: "I want to acknowledge your team's understanding and expertise in their efforts to meet our project goals. This is what we wanted to achieve. It has also allowed us to discover the expertise of the NimbusBytes team and the value you bring to your clients. I would like to give them a 5 rating for their exceptional work ethic and quality.      ",
      img: testImg
    },
    {
      name: 'CTO - A leading E-commerce solution provider      ',
      desp: "We have been very impressed with NimbusBytes's flexibility and dedication to our project. The team collaboration and round-the-clock availability were exceptional, considering the differences in geographies and time. They helped us mitigate the chances of delays and made the working experience seamless.      ",
      img: testImg
    },
    {
      name: 'VP - A Saas provider of a leading logistics company',
      desp: "Your understanding, knowledge of cloud-managed service, and proactiveness in helping us complete our project on time were commendable. I recommend Nimbusbytes to future clients for their cloud computing services requirements.",
      img: testImg
    },
    {
      name: 'CTO - A leading software solutions provider      ',
      desp: "I acknowledge your understanding and expertise in software development services. Thank you for meeting our expectations and timeline while ensuring a seamless process. Your efforts and experience helped us reach the delivery stage in a pre-defined time. Highly recommended!      ",
      img: testImg
    },
  ]

  const handleMouseEnter = () => {
    setAutoPlay(false); // Stop autoplay when mouse enters the card
  };

  const handleMouseLeave = () => {
    setAutoPlay(true); // Resume autoplay when mouse leaves the card
  };


  useEffect(() => {
    let intervalId;

    if (autoPlay) {
      intervalId = setInterval(() => {
        setCurrentCard(prevCard => {
          // Pop the first testimonial and push it to the end
          const updatedTestimonials = [...testimonials.slice(1), testimonials[0]];
          return (prevCard + 1) % updatedTestimonials.length;
        });
      }, 3000); // Change this value to adjust auto play speed (in milliseconds)
    }

    return () => clearInterval(intervalId);
  }, [autoPlay, testimonials.length]);

  const handleTouchStart = () => {
    setAutoPlay(false); // Stop autoplay when touch starts
  };

  const handleTouchEnd = () => {
    setAutoPlay(true); // Resume autoplay when touch ends
  };


  const changeButtonState = (state) => {
    if (state === "DECREMENT") {
      setCurrentCard(prevCard => (prevCard - 1 + testimonials.length) % testimonials.length);
    } else if (state === "INCREMENT") {
      setCurrentCard(prevCard => (prevCard + 1) % testimonials.length);
    }
  };

  // const toggleAutoPlay = () => {
  //   setAutoPlay(prevState => !prevState);
  // };

  useEffect(() => {
    const handleScroll = () => {
      const testimonialSection = document.querySelector('.testimonial__wrapper');
      const top = testimonialSection.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (top < windowHeight && !autoPlay) {
        setAutoPlay(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [autoPlay]);


  return (
    <div className="testimonial__wrapper">
      <div className='testimonial'>
      <FaQuoteRight className='qoutes'/>

        <div className="testimonial__left">
          <h4>Client <span>Testimonials</span></h4>
          <p>Empower innovation and enhance your process workflow performance with our high-quality IT services.</p>
          <div className="controls desk">
            <div className={`control__circle ${currentCard > 0 ? "active" : ""}`} onClick={() => changeButtonState("DECREMENT")}>
              <MdOutlineKeyboardArrowLeft />
            </div>
            <div className={`control__circle ${currentCard < testimonials.length - 1? "active" : ""}`} onClick={() => changeButtonState("INCREMENT")}>
              <MdOutlineKeyboardArrowRight />
            </div>
          </div>
        </div>
        <IconsGradient right="10%" top="0" rotate="210deg" />
        <TestimonialCards testimonials={testimonials} currentCard={currentCard} handleMouseEnter={handleMouseEnter} handleTouchStart={handleTouchStart} 
      handleTouchEnd={handleTouchEnd}
          handleMouseLeave={handleMouseLeave} />
        <div className="controls mob">
            <div className={`control__circle ${currentCard > 0 ? "active" : ""}`} onClick={() => changeButtonState("DECREMENT")}>
              <MdOutlineKeyboardArrowLeft />
            </div>
            <div className={`control__circle ${currentCard < testimonials.length - 1? "active" : ""}`} onClick={() => changeButtonState("INCREMENT")}>
              <MdOutlineKeyboardArrowRight />
            </div>
          </div>
      </div>
    </div>
  )
}

export default Testimonial