import React from 'react'
import './topIndustries.css'
import { Link } from 'react-router-dom'
import img1 from '../../images/capsule1.svg'
import img2 from '../../images/capsule2.svg'
import img3 from '../../images/capsule3.svg'
import img4 from '../../images/capsule4.svg'
import img5 from '../../images/capsule5.svg'
import img6 from '../../images/capsule6.svg'
import Gradient from '../UI/Gradient'

const TopIndustries = () => {
  return (
    <div className="topIndustires__wrapper">
       <Gradient width={200} height={200} top={0} left="0%" />
          <Gradient width={200} height={200} top={200} left="0%"/>
          <Gradient width={200} height={200} top={400} left="0%" />
    <div className='topIndustries'>
      <div className="topIndustires__left">
        <h3> <span>Top Industries</span>  <span>We Serve</span> </h3>
        <p>Our dedicated software developers have experience in providing IT services in various industries, including Banking, Finance, E-commerce, Healthcare, Education, and more.</p>
        <Link onClick={() => window.scrollTo(0,0) } className='red-btn' to='/contactus'><span></span><span></span><span></span><span></span><span>Accelerate Your Business</span> </Link>
      </div>
     
      <div className="topIndustires__right">
        <div className="topIndustries__img_right topIndustries__img1">
          <div className="capsule">
            <div className="capsule__content">
              <h4 className='capsule__title'>Transportation and Logistics</h4>
            </div>
            <img className='img' src={img1} alt="nimbusbytes" />
          </div>
          <div className="capsule">
            <img className='img' src={img6} alt="nimbusbytes" />
            <div className="capsule__content">
              <h4 className='capsule__title'>Fintech</h4>
            </div>
          </div>
          <div className="capsule">
          <div className="capsule__content">
              <h4 className='capsule__title'>Education and eLearning</h4>
            </div>
            <img className='img' src={img4} alt="nimbusbytes" />
          </div>
        </div>
        <div className="topIndustries__img_right topIndustries__img2">
        <div className="capsule">
        <img className='img' src={img3} alt="nimbusbytes" />
            <div className="capsule__content">
              <h4 className='capsule__title'>Healthcare</h4>
            </div>
          </div>
          <div className="capsule">
            <div className="capsule__content">
              <h4 className='capsule__title'>E-commerce</h4>
            </div>
            <img className='img' src={img2} alt="nimbusbytes" />
          </div>
          <div className="capsule">
            <img className='img' src={img5} alt="nimbusbytes" />
            <div className="capsule__content">
              <h4 className='capsule__title'>Lifestyle</h4>
            </div>
          </div>
        </div>
        <div className="topIndustries__img_right topIndustries__img3">
          <div className="capsule">
            <div className="capsule__content">
              <h4 className='capsule__title'>Education and eLearning</h4>
            </div>
            <img className='img' src={img3} alt="nimbusbytes" />
          </div>
          <div className="capsule">
            <img className='img' src={img4} alt="nimbusbytes" />
            <div className="capsule__content">
              <h4 className='capsule__title'>Healthcare</h4>
            </div>
          </div>
        </div>
      </div>
     
      </div>
      <Gradient width={200} height={200} top={0} right="0%" />
          <Gradient width={200} height={200} top={200} right="0%"/>
          <Gradient width={200} height={200} top={400} right="0%" />
    </div>
  )
}

export default TopIndustries