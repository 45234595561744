import React from 'react'
import './digitalMarketing.css'
import banner from '../../../images/security-circle.png'
import HeroBanner from '../../../components/HeroBanner/HeroBanner'
import HoriBanner from '../../../components/HoriBanner/HoriBanner'
import HeroTabs from '../../../components/HeroTabs/HeroTabs'
import SimpleBanner from '../../../components/SimpleBanner/SimpleBanner'
import bubble from '../../../images/bubble.svg'
import MigrateCards from '../../../components/MigrateCards/MigrateCards'
import migrateAnal1 from '../../../images/migrate-anal-1.svg'
import SecurityCards from '../../../components/SecurityCards/SecurityCards'
import Breadcrum from '../../../components/Breadcrum/Breadcrum'

import security1 from '../../../images/digital/security1.svg'
import security2 from '../../../images/digital/security2.svg'
import security3 from '../../../images/digital/security3.svg'
import security4 from '../../../images/digital/security4.svg'
import security5 from '../../../images/digital/security5.svg'
import security6 from '../../../images/digital/security6.svg'

import horiIcon1 from '../../../images/digital/horiImg1.svg'
import horiIcon2 from '../../../images/digital/horiImg2.svg'
import horiIcon3 from '../../../images/digital/horiImg3.svg'
import horiIcon4 from '../../../images/digital/horiImg4.svg'
import horiIcon5 from '../../../images/digital/horiImg5.svg'

import tab1 from '../../../images/digital/tab1.svg'
import tab2 from '../../../images/digital/tab2.svg'
import tab3 from '../../../images/digital/tab3.svg'
import tab4 from '../../../images/digital/tab4.svg'
import tab5 from '../../../images/digital/tab5.svg'
import tab6 from '../../../images/digital/tab6.svg'

import migrateComp from '../../../images/digital/migrateComp.svg'
import { Helmet } from 'react-helmet'
import headerImg1 from '../../../images/digital/digitalHeader1.svg'
import headerImg2 from '../../../images/digital/digitalHeader2.svg'

const DigitalMarketing = ({setActiveLink}) => {

    var securityCards = [
        {
          img: security1,
          title: "Search Engine Optimization",
          desp: "Optimize your rankings in search results for highly competitive keywords. Increase conversion by driving."
        },
        {
          img: security2,
          title: "Social Media Marketing",
          desp: "We use advanced social media strategies to optimize your site’s brand visibility, customer engagement, and conversion rates."
        },
        {
          img: security3,
          title: "Content Marketing",
          desp: "We create high-performing content that reaches the target audience and grows your business. "
        },
    
        {
          img: security4,
          title: "Marketing Automation",
          desp: "With our marketing automation solutions, you can identify potential cost-effective customers and automate the process of translating those leads into integrated sales. "
        },
        {
          img: security5,
          title: "Paid Search Marketing",
          desp: "Use our paid search and marketing services to improve your rankings and impression share. "
        },
        {
          img: security6,
          title: "Email Marketing",
          desp: "Our digital marketing team can tailor your email marketing campaigns to increase leads and conversions."
        },
    
      ]

    var horiCards = [
        {
            img: horiIcon1,
            title: "Awareness",
            desp: "Our journey starts with building brand awareness. Through targeted content, engaging advertising, and engaging social media campaigns, we position your brand as a noticeable presence in the digital landscape.  "
        },
        {
            img: horiIcon2,
            title: "Interest",
            desp: " As we shift from consciousness, we focus on catching attractions. We deepen the connection with your audience through compelling storytelling, informative content, and interactive experiences, turning visitors into actively engaged prospects."
        },
        {
            img: horiIcon3,
            title: "Consideration",
            desp: "After creating interest across target prospects, we make a sense of urgency. We nurture leadership and build trust and confidence by providing valuable insights, addressing concerns, and positioning your product or service as a solution. "
        },

        {
            img: horiIcon4,
            title: "Conversion",
            desp: " As engaged prospects turn into customers, the decision phase arises. We optimize conversion points, simplify ordering, and implement a compelling call to action. Personalized packages and incentives create an environment where leaders confidently become valuable customers. "
        },
        {
            img: horiIcon5,
            title: "Loyalty",
            desp: "Our commitment extends beyond sales. We are focused on converting customers into advocates and offer an exceptional post-purchase experience. From exclusive offers to personalized communications, we strive to build long-term loyalty, turning satisfied customers into brand ambassadors."
        }
    ]

    var serviceTabs = [
        {
            img: tab1,
            title: "Earning Traffic    ",
            desp: `Fetch 10X traffic to your website with tailor-made and comprehensive digital marketing services.`
        },
        {
            img: tab2,
            title: "Better Conversion Rates      ",
            desp: `Increase your conversion rate with our foolproof, tested conversion rate optimization strategies.`
        },
        {
            img: tab3,
            title: "ROI Projections          ",
            desp: `Invest in online marketing services and get a 45% average conversion increase.`
        },
        {
            img: tab6,
            title: "In-Depth Competitor Analysis ",
            desp: `Conduct In-depth competitor analysis with the help of our expert to create the latest metrics and generate actionable insights.`
        },
        {
            img: tab4,
            title: "Complete Business Audit   ",
            desp: `We conduct regular business audits to ensure your customer experience aligns with all stages of strategy development.`
        },
        {
            img: tab5,
            title: "Real-Time Results     ",
            desp: `Our digital marketing company pursues a 360-degree approach to meet online marketing and delivers real-time results.`
        }
    ]

    let stats = [
        { text: 'Successful Digital Marketing Campaigns ', stats: '100+' },
        { text: 'Projects Delivered', stats: '1000+', styles: { marginTop: "auto" } },
        { text: 'Certified Digital Marketing Experts', stats: '' },
        { text: 'Experience of', stats: '3 Years' },
        { text: 'Proven Digital Marketing Strategies', stats: '' }
    ]

    const migrateCards = [
        {
            title:"Healthcare",
            desp: "Leverage advanced SEO and social media marketing services and solutions. Our digital marketing agency has dedicated experts with in-depth knowledge of the healthcare industry and its verticals."
        },
        {
            title:"Finance ",
            desp: "Our digital marketing services provider offers cost-effective digital solutions and strategies to attain prospective clients and ensure successful lead generation and sales.   ",
        },
        {
            title: 'Ecommerce',
            desp: " Enhance your physical or online store presence with our digital marketing services in the eCommerce sector. We can help you boost sales and reduce the chances of shopping cart abandonment challenges." 
        },
        {
            title:'Lifestyle',
            desp: "Leverage our digital marketing services and enhance your search engine ranking and sales seamlessly across the geography.",
        },
        {
            title: "Education",
            desp: "Our experienced digital marketing experts can assist you in building your brand identity and promoting your services by implementing tailor-made content marketing strategies."
        },{
            title:"Travel",
            desp:"Our certified digital marketing services provider enables businesses in the transportation & logistics domain to compete and lead in the top searches on Google's search engine."
        }
    ]

    console.log('software');


    return (
        <div className='digital'>

            <Helmet>
                <meta name='title' data-react-helmet="true" property="og:title" content="Strategic Digital Marketing Services and Solutions | NimbusBytes" />
                <meta name='description' data-react-helmet="true" property="og:description" content="Boost your online presence and drive results with our Digital Marketing services. Tailored strategies to elevate your brand in the digital landscape." />
            </Helmet>

            <div className="title__banner all-clouds">
                <div className="digitalHeaderImg1">
             <img src={headerImg1} className='' alt="nimbusbytes" />
            </div>

                <h2 className='main'>Digital Marketing <span className='title__small'>Services</span></h2>
                <Breadcrum parentLink="/services" parentName="Services" childLink="/services/digital-marketing" childName="Digital Marketing Services"/>
                
                <div className="digitalHeaderImg2">
                <img src={headerImg2} className='' alt="nimbusbytes" />
                </div>
            
            </div>

            <HeroBanner setActiveLink={setActiveLink} title="Optimize Your Website Traffic by 10X" titleDesp=" With Our Digital Marketing Services" banner={banner} stats={stats}
                desp="Leverage comprehensive digital marketing services with Nimbusbytes and make your business searchable online on the first page of the Google search engine."
            />

            <SecurityCards title="ROI-Driven  " redTitle="Digital Marketing Services" setActiveLink={setActiveLink}
                securityCard={securityCards} desp="Achieve the desired results with our best-in-class digital marketing services." />

            <HoriBanner title="Our Five Stages " imgBg={migrateAnal1} redTitle="Sales Funnel for Marketing"
                horiCard={horiCards}
                titleDesp="" desp="We strictly follow the five sales funnel steps to ensure our clients achieve successful results through inbound marketing.                 " />

            <HeroTabs imgBg={bubble} title="Why Choose NimbusBytes for " redTitle="Digital Marketing Services?" desp="NimbusBytes is a leading global digital marketing agency offering best-in-class digital marketing services." horiTabs={serviceTabs} />

            <MigrateCards headImg={migrateComp} firstImg={migrateAnal1}  title="Industries " redTitle="We Serve" desp="NimbusBytes is a leading digital marketing company providing online marketing services to various businesses in the industry to expand their business and profitability."  titleDesp="" cardData={migrateCards} />
            
            <SimpleBanner btnText="Let's Connect" link="/contactus" setActiveLink={setActiveLink}
            title="Ready to Maximize Your Reach?" desp="Book Your 30-Minute Free Consultation Now" />
            
        </div>
    )
}

export default DigitalMarketing