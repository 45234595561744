import React from 'react'
import './heroTabs.css'

const HeroTabs = ({ title, titleDesp, desp, horiTabs, redTitle, imgBg }) => {
    // const [activeTab, setActiveTab] = useState(1); // Defaulting the active tab to index 1

    // const handleTabClick = (index) => {
    //   setActiveTab(index);
    // };

    console.log("HeroTabs");

    return (
        <div className='heroTabs__container'>
            <div className="service__tabs heroTabs">
                <h3 className='main text-align-center'>{title} {titleDesp ? <span className='title__small'>{titleDesp}</span> : ''} {redTitle ? <span className='redTitle'>{redTitle}</span> : ''}</h3>
                {desp && <p className='heroTab__desp'>{desp}</p>}
                <div className="service__container">
                    {horiTabs.map((data, index) => (
                        <div key={index} className={`service__tab ${data.list ? 'left-align' : ''}`}>
                            <div className="service-img">
                                <img src={data.img} alt="demo" />
                            </div>
                            <h4>{data.title}</h4>

                            {
                                data.desp ? (<p>{data.desp}</p>) : (
                                    <ul>
                                        {data.list.map((ele,index) => (
                                            <li key={index}>{ele}</li>
                                        ))}
                                    </ul>
                                )
                            }
                            
                        </div>
                    ))}
                </div>
            </div>
            <img src={imgBg} className='bubble' alt="nimbusbytes" />

        </div>
    )
}

export default HeroTabs