import './App.css';
import {  Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header/Header';
import Cloud from './pages/Services/Cloud/Cloud';
import Analytics from './pages/Services/Analytics/Analytics';
import CyberSecurity from './pages/Services/CyberSecurity/CyberSecurity';
import Software from './pages/Services/Software/Software';
import Migrate from './pages/Services/Migrate/Migrate';
import Devops from './pages/Services/Devops/Devops';
import DigitalMarketing from './pages/Services/DigitalMarketing/DigitalMarketing';
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import FAQ from './pages/FAQ/FAQ';
import ContactUsPage from './pages/ContactUs/ContactUsPage';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import OurTeam from './pages/OurTeam/OurTeam';
import LegalNotice from './pages/LegalNotice/LegalNotice';
import { Helmet } from 'react-helmet';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Services from './pages/Services/Services';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import { useState } from 'react';
import Cumuli from './pages/Projects/Cumuli/Cumuli';
import Projects from './pages/Projects/Projects';
import CumuliILogo from './images/cumuli/cumuli.png'
import mainLogo from './images/logo.png'

function App() {

  // const handleLinkClick = (to) => {
  //   setToggleNav(false)
  // };

  console.log('app');
  const [activeLink, setActiveLink] = useState('/');
  let logo = mainLogo
  let pageClass = ''

  // useEffect(() => {
  //   const browser = bowser.getParser(window.navigator.userAgent);
  //   const browserName = browser.getBrowserName().toLowerCase();
    
  //   // Load browser-specific CSS dynamically
  //   if(browserName === 'firefox'){
  //   import(`./firefox-styles.css`)
  //     .then(() => console.log(`${browserName}-styles.css loaded successfully`))
  //     .catch(error => console.error(`Error loading ${browserName}-styles.css:`, error));
  //   }
  // }, []);

  
  const location = useLocation();

  // Define routes where the header and footer should not be shown
  const noHeaderFooterRoutes = ['/projects/cumuli'];

  switch (location.pathname) {
    case noHeaderFooterRoutes[0]:
        // logo = CumuliILogo
        pageClass = 'cumuli'
      break;
  
    default:
      logo = mainLogo
      break;
  }

  // Check if current route matches the demo page route
  const hideHeaderFooter = noHeaderFooterRoutes.includes(location.pathname);

  return (
      <div className={`App bg-color ${pageClass}`}>
        <Helmet>
          <meta name='title' data-react-helmet="true" property="og:title" content="Nimbusbytes, an information technology (IT) services provider" />
          <meta name='description' data-react-helmet="true" property="og:description" content="Nimbusbytes is a leading information technology (IT) services company offering customized IT solutions." />
        </Helmet>
        <Header setActiveLink={setActiveLink} logo={logo}  activeLink={activeLink}/>
        <Routes>
          <Route path='/' element={<Home setActiveLink={setActiveLink} />} />
          <Route path='/our-team' element={<OurTeam setActiveLink={setActiveLink} />} />
          <Route path='/about' element={<AboutUs setActiveLink={setActiveLink} />} />
          <Route path='/services' element={<Services setActiveLink={setActiveLink} />} />
          <Route path='/services/cloud' element={<Cloud setActiveLink={setActiveLink} />} />
          <Route path='/services/analytics' element={<Analytics setActiveLink={setActiveLink} />} />
          <Route path='/services/cybersecurity' element={<CyberSecurity setActiveLink={setActiveLink} />} />
          <Route path='/services/software-services' element={<Software setActiveLink={setActiveLink} />} />
          <Route path='/services/migrate-modernize' element={<Migrate setActiveLink={setActiveLink} />} />
          <Route path='/services/devops' element={<Devops setActiveLink={setActiveLink} />} />
          <Route path='/services/digital-marketing' element={<DigitalMarketing setActiveLink={setActiveLink} />} />
          <Route path='/faq' element={<FAQ setActiveLink={setActiveLink} />} />
          <Route path='/contactUs' element={<ContactUsPage setActiveLink={setActiveLink} />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy setActiveLink={setActiveLink} />} />
          <Route path='/legal-notice' element={<LegalNotice setActiveLink={setActiveLink} />} />
          <Route path='/projects' element={<Projects setActiveLink={setActiveLink} />} />
          <Route path='/projects/cumuli' element={<Cumuli setActiveLink={setActiveLink}/>} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>

        <ScrollToTop />

            <footer>
              <div className="footer-container">
                {/* Footer content here */}
              </div>
            </footer>
            <div className="footer__copyright">
              Copyright © 2024 NimbusBytes.
            </div>
      </div>
  );
}

export default App;
