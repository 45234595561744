import React from 'react'
import './migrate.css'
import banner from '../../../images/bg-circle.svg'
import HeroBanner from '../../../components/HeroBanner/HeroBanner'
import HoriBanner from '../../../components/HoriBanner/HoriBanner'
import HeroTabs from '../../../components/HeroTabs/HeroTabs'
import SimpleBanner from '../../../components/SimpleBanner/SimpleBanner'
import bubble from '../../../images/bubble.svg'
import cloud from '../../../images/cloud.svg'
import MigrateCards from '../../../components/MigrateCards/MigrateCards'
import analytics from '../../../images/analytics-title.svg';
import migrateAnal2 from '../../../images/migrate-anal-2.svg'
import migrateAnal1 from '../../../images/migrate-anal-1.svg'
import DataChallanges from '../../../components/DataChallenges/DataChallanges'
import Breadcrum from '../../../components/Breadcrum/Breadcrum'


import tab1 from '../../../images/migrate/tab1.svg'
import tab2 from '../../../images/migrate/tab2.svg'
import tab3 from '../../../images/migrate/tab3.svg'

import horiIcon1 from '../../../images/migrate/horiIcon1.svg'
import horiIcon2 from '../../../images/migrate/horiIcon2.svg'
import horiIcon3 from '../../../images/migrate/horiIcon3.svg'
import horiIcon4 from '../../../images/migrate/horiIcon4.svg'
import horiIcon5 from '../../../images/migrate/horiIcon5.svg'
import horiIcon6 from '../../../images/migrate/horiIcon6.svg'
// import migrateComp from '../../../images/migrate/migrateComp.svg'
import dataChallengeImg from '../../../images/migrate/dataChallengeImg.svg'

import chall2 from '../../../images/challengeIcon2.svg'
import chall1 from '../../../images/security2.svg'
import { Helmet } from 'react-helmet'

import headerImg1 from '../../../images/migrate/migrateHeader1.svg'
import headerImg2 from '../../../images/migrate/migrateHeader2.svg'
import headerImg3 from '../../../images/migrate/migrateHeader3.svg'

const Migrate = ({setActiveLink}) => {
    var horiCards = [
        {
            img: horiIcon1,
            title: "Cloud Advisory Services",
            desp: "Assess the current state of your business applications and infrastructure in the current environment and find the safest and fastest route to the cloud.            "
        },
        {
            img: horiIcon2,
            title: "Cloud Management and Optimization",
            desp: " Enable the cloud tools and services you need to run optimized with built-in automated compliance and governance."
        },
        {
            img: horiIcon3,
            title: "Cloud Assessment and Planning Services",
            desp: " Leverage NimbusBytes suite of services including insights to rapidly adopt the cloud and accelerate time-to-value for your investment."
        },

        {
            img: horiIcon4,
            title: "Cloud Migration Strategy",
            desp: " Build a path to migration that puts your organization’s needs and business goals first and optimizes your value."
        },
        {
            img: horiIcon5,
            title: "Cloud Application Migration and Modernization ",
            desp: " Build, modernize, and manage your critical business applications in a secure cloud environment."
        },
        {
            img: horiIcon6,
            title: "AI Integration for Intelligent Operations",
            desp: "Our customized AI solutions align with your specific business objectives, enhancing overall operational excellence."
        },

    ]

    const challengeCards = [{
        title: "Discovery Phase",
        list: [{
            title: 'Comprehensive Analysis: ',
            desp: " We conduct an in-depth examination of your existing infrastructure, identifying strengths, weaknesses, and potential areas for improvement."
        },
        {
            title: 'Challenge Identification:       ',
            desp: ' Our experts carefully analyze potential obstacles and challenges, ensuring a thorough understanding of the landscape before moving forward.'
        }]},{
        title: "Strategic Planning",
        list:
            [{
                title: 'Bespoke Roadmap: ',
                desp: "Crafting a customized migration and modernization roadmap that aligns with your specific business objectives."
            },
            {
                title: 'Collaborative Approach:',
                desp: "We work closely with your team, fostering collaboration to guarantee that our strategy seamlessly integrates with your overarching business goals."
            }]},{
        title: "Efficient Execution",
        list: [{
            title: 'Seamless Migration:',
            desp: "Leveraging advanced tools and methodologies, we migrate your data and applications with minimal disruption to your daily operations"
        },
        {
            title: 'Precision in Modernization:',
            desp: "Our approach to modernization involves utilizing cutting-edge technology to rapidly upgrade systems, ensuring optimal performance."
        }]},{
        title: "Quality Assurance",
        list: [{
            title: ' Rigorous Testing Protocols:',
            desp: ' Before and during implementation, our team employs rigorous testing protocols to identify and rectify any potential issues.'
        },
        {
            title: 'Agile Problem Solving:',
            desp: ' In the event of unforeseen challenges, our team responds with agility and precision, maintaining project timelines without compromising quality.'
        }]},{
        title: "Post-Implementation Support",
        list: [{
            title: ' Continuous Monitoring: ',
            desp: ' We provide ongoing monitoring and support post-implementation to ensure the stability and performance of your newly migrated and modernized systems.            '
        },
        {
            title: 'Optimization: ',
            desp: ' Our commitment extends to fine-tuning and optimizing systems as needed, ensuring sustained success in the ever-evolving digital landscape.'
        }]
    }]

    var serviceTabs = [
        {
            img: tab1,
            title: "Discovery and Assessment",
            list: [
                "Cloud Economic Assessment",
                "Workshops with business stakeholders",
                "Understand business needs and scope",
                "Evaluate TCO",
                "Understand Detailed Portfolio/Workloads",
                "Identify Target Cloud",
                "Identify potential threats and roadblocks & plan workarounds"]
        },
        {
            img: tab2,
            title: "Readiness and Planning ",
            list: [
                "Apps Identification & Prioritization for Migration & Modernization",
                "Landing Zone/Infrastructure Planning",
                "Migration Governance",
                "People, Skills, Change Readiness",
                " Security, Risk, and Compliance Planning",
                "Define Migration Strategy",
                "Migration-Specific Texting Approach"]
        },
        {
            img: tab3,
            title: "Migrate and Manage",
            list: [
                "Pre-migration to validate migration strategy",
                "Application & Infrastructure Migration in multiple sprints",
                "Testing & Validation after each sprint and Deploy",
                "Document Finding & Plan Fixes",
                "Building Security Controls",
                "Performance Testing",
                "Integration Testing",
                "UAT",
                "Performance and Cost Optimization"]
        }
    ]

    let stats = [
        { text: 'Increase migration success rates by ', stats: '25%' },
        { text: 'Ensure reducing downtime by ', stats: '30%', styles: { marginTop: "auto" } },
        { text: 'Boost confidence with cloud services, ensuring a improvement in security by', stats: '20%' },
        { text: 'Reduce operational costs by ', stats: '15%' }
    ]

    const migrateCards = [
        {
            title: "Proven Expertise",
            desp: " With a record of delivering successful projects and satisfied clients, our team brings extensive years of expertise to every engagement. Our seasoned professionals understand the intricacies of IT transformation and bring a proactive approach to every challenge.",
        },
        {
            title: "Tailored Solutions            ",
            desp: "We recognize that every business is unique. Our solutions are not one-size-fits-all; we invest our time in having knowledge regarding your specific needs and craft tailored strategies that align seamlessly with your objectives.    ",
        },
        {
            title: 'Innovation at Core',
            desp: " Innovation is in our DNA. We lead the competitive market, integrating the latest technologies and methodologies. This commitment to innovation ensures that your business benefits from cutting-edge solutions that stand the test of time."
        },
        {
            title: 'Client-Centric Approach',
            desp: "Your success is our priority. Our client-centric approach means that we actively listen to your challenges, collaborate closely throughout the process, and remain dedicated to delivering solutions that exceed expectations.",
        },
        {
            title: "Transparent Communication",
            desp: "Open and transparent communication is the foundation of our client relationships. We keep you informed at every stage, providing transparency and clarity to build trust and confidence. "
        }, {
            title: "Continuous Improvement",
            desp: "The digital landscape evolves, and so do we. Our commitment to continuous improvement means staying proactive in refining our processes, staying abreast of industry trends, and ensuring your solutions remain cutting-edge."
        }
    ]

    console.log('software');


    return (
        <div className='cloud-migrate'>

            <Helmet>
                <meta name='title' data-react-helmet="true" property="og:title" content="Seamless Migration & Modernization | NimbusBytes" />
                <meta name='description' data-react-helmet="true"  property="og:description" content="Upgrade and optimize your systems with our Migrate and Modernize services. Effortlessly transition to advanced technologies to achieve excellent performance." />
            </Helmet>

            <div className="title__banner all-clouds">
                <div className="migrateHeaderImg2">
                <img src={headerImg1}  alt="nimbusbytes" />
                </div>
                <div className="migrateHeaderImg3">
                <img src={headerImg3} alt="nimbusbytes" />
                </div>
                <h2 className='main'>Cloud Migrate and Modernize <span className='title__small'>Services</span></h2>
                <Breadcrum parentLink="/services" parentName="Services" childLink="/services/migrate-modernize" childName="Cloud Migrate and Modernize Services" />
                <div className="migrateHeaderImg1">
                <img src={headerImg2}  alt="nimbusbytes" />
                </div>
            </div>

            <HeroBanner title="Cloud Migrate and " titleDesp="Modernize Services" banner={banner} stats={stats} setActiveLink={setActiveLink}
              btnText="Accelerate Your Business"  desp="Transform your tech effortlessly with our Migration, Modernization, and Cloud Migration services. Your pathway to a secure future – Trust the journey to simplify, amplify, and innovate intelligently. Partner with us and achieve:"
            />

            <DataChallanges title="Cloud Migrate and Modernize Service" redTitle="Our Approach at NimbusBytes" desp="" challengeCards={challengeCards} img1={dataChallengeImg}
                img2={chall1} img3={chall2} capsule1Desp="Cloud Migrate with Secure Data" capsule2Desp="Achieve High Retention Rate" />

            <HoriBanner title="Cloud Migrate and " imgBg={cloud}
                horiCard={horiCards}
                titleDesp=" Modernize Services" desp="  At NimbusBytes, we have a dedicated team of experts specializing in Cloud Migration and Modernization services, ensuring a seamless transition to a more agile and efficient digital landscape. Here's a closer look at our offerings:
                " />

            <HeroTabs imgBg={bubble} title=" Our Migration And Application" titleDesp=" Modernization Framework" desp="We deliver unmatched efficiency and accelerate valuable time for our clients with our robust, comprehensive solutions and services that can help you wherever you are in your transformation journey   " horiTabs={serviceTabs} />

            <MigrateCards headImg={analytics} firstImg={migrateAnal1} secondImg={migrateAnal2} title="What Makes " redTitle="NimbusBytes different?" desp="Discover what makes us the preferred choice for transformative IT solutions" cardData={migrateCards} />

            <SimpleBanner title1="Book Your 30-Minute" redText="FREE" topDesp="Innovation Awaits!" link="/contactus" setActiveLink={setActiveLink}
                btnText="Let’s Collaborate" title=" Consultation Now." />

        </div>
    )
}

export default Migrate