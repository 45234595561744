import React from "react"

import "./gradient.css";

const Gradient = ({ width, height, top, right, left, bottom }) => {

    let styles = {
        content: '',
        position: "absolute",
        borderRadius: "297.181px",
        background: "rgba(0, 194, 255, 0.99)",
        filter: "blur(170px)",
        width: width,
        height: height,
        ...(top && { top }),
        ...(right && { right }),
        ...(left && { left }),
        ...(bottom && { bottom }),
    }

    return <div className="gradient__box" style={styles}></div>
}

export const IconsGradient = ({ top, right, left, bottom, rotate }) => {
    let styles = {
        ...(top && { top }),
        ...(right && { right }),
        ...(left && { left }),
        ...(bottom && { bottom }),
        ...(rotate && { rotate }),
    }

    return (
        <div className="icons__gradient" style={styles}>
            <div className="polygon"></div>
            <div className="star"></div>
            <div className="ellipse"></div>
            <div className="rect"></div>
        </div>
    );
}

export default Gradient;
