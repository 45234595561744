import React from 'react'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { Link } from 'react-router-dom'
import './breadcrum.css'

const Breadcrum = ({parentName,parentLink,childName,childLink, setActiveLink}) => {
  return (
    <div className="breadcrum"><Link to={parentLink} onClick={() => setActiveLink(parentLink)}>{parentName}  </Link> {childLink ?  <MdOutlineKeyboardArrowRight /> : "" } <Link to={childLink} className='active__link' >  {childName}</Link></div>
  )
}

export default Breadcrum