import React from 'react'
import './cloud.css'
import banner from '../../../images/bg-circle.svg'
import HeroBanner from '../../../components/HeroBanner/HeroBanner'
import HoriBanner from '../../../components/HoriBanner/HoriBanner'
import HeroTabs from '../../../components/HeroTabs/HeroTabs'
import SimpleBanner from '../../../components/SimpleBanner/SimpleBanner'
import bubble from '../../../images/bubble.svg'
import cloud from '../../../images/cloud.svg'
import Breadcrum from '../../../components/Breadcrum/Breadcrum'

import horiIcon1 from '../../../images/cloud/horiIcon1.svg'
import horiIcon2 from '../../../images/cloud/horiIcon2.svg'
import horiIcon3 from '../../../images/cloud/horiIcon3.svg'
import horiIcon4 from '../../../images/cloud/horiIcon4.svg'
import horiIcon5 from '../../../images/cloud/horiIcon5.svg'
import horiIcon6 from '../../../images/cloud/horiIcon6.svg'

import tabImg1 from '../../../images/cloud/tabImg1.svg'
import tabImg2 from '../../../images/cloud/tabImg2.svg'
import tabImg3 from '../../../images/cloud/tabImg3.svg'
import tabImg4 from '../../../images/cloud/tabImg4.svg'
import tabImg5 from '../../../images/cloud/tabImg5.svg'
import tabImg6 from '../../../images/cloud/tabImg6.svg'
import { Helmet } from 'react-helmet'

const Cloud = ({setActiveLink}) => {
    var horiCards = [
        {
            img: horiIcon1,
            title: "Managed Private Cloud Services",
            desp: "We offer fully managed private cloud IaaS/PaaS for computing, storage, and containers and managed services for private and hybrid clouds on customer-owned systems."
        },
        {
            img: horiIcon2,
            title: "Managed Public Cloud Services",
            desp: "We offer managed services for a single provider and multi-cloud environments, and we can help you manage your hybrid IT and hybrid cloud — all from a single interface.            "
        },
        {
            img: horiIcon3,
            title: "Managed Application Services",
            desp: "We have deep expertise in application deployment, including ERP applications, whether deployed in a private cloud or hybrid cloud – and we provide services from analytics to migration to deployment."
        },

        {
            img: horiIcon4,
            title: "Managed Workplace Services",
            desp: "NimbustBytes provides leading services in the areas of collaboration, mobility, facility support, and device management."
        },
        {
            img: horiIcon5,
            title: "Managed Networking Services ",
            desp: "We help optimize and modernize enterprise networks and edge deployments for enhanced scalability and availability with tools, automation, orchestration, and management."
        },
        {
            img: horiIcon6,
            title: "Managed Security and Resiliency            ",
            desp: "We provide a wide range of safety and resilience services, inspections, operations, incident recovery, and management services."
        },

    ]

    var serviceTabs = [
        {
            img: tabImg1,
            title: "The specifics of a Managed Private Cloud",
            desp: "Take our bespoke private cloud solutions to your unique requirements, delivering unparalleled control, Security, and performance."
        },
        {
            img: tabImg2,
            title: "Agile Optimization in the Public Cloud",
            desp: "Experience the agility of the public cloud at no charge. Our expert staff ensures the best use of materials for maximum performance."
        },
        {
            img: tabImg3,
            title: "Application Mastery",
            desp: "We don’t just manage applications; We raise them up. Our proactive strategies and ongoing support increase your software performance and improve productivity."
        },
        {
            img: tabImg4,
            title: "Workplace solutions that work",
            desp: " Keep your words effortlessly simple. Our enterprise project management system empowers your team, allowing them to focus on what matters most. "
        },
        {
            img: tabImg5,
            title: "Excellent network",
            desp: "Stay connected and responsive. Our communication services streamline operations and provide seamless communication and collaboration. "
        },
        {
            img: tabImg6,
            title: "Robust Security and modifications",
            desp: "Confidence in our robust security system. From threat detection to disaster recovery, we relentlessly protect your data and operations."
        },
    ]

    let stats = [
        { text: 'Reduced Cyber Risks by', stats: '30%' },
        { text: 'Optimize Efficiency by', stats: '40%', styles: { marginTop: "auto" } },
        { text: 'Lower Operational Costs by', stats: '20%' },
        { text: 'Increased Agility by', stats: '25%' },
        { text: 'Ensure Downtime Reduction by', stats: '15%', styles: { marginBottom: "auto" } },
    ]

    console.log('cloud');


    return (
        <div className='clouds'>
            <Helmet>
                <meta name='title' data-react-helmet="true" property="og:title" content="Efficient Cloud Management Solutions | NimbusBytes" />
                <meta name='description' data-react-helmet="true" property="og:description" content="Achieve scalability and reliability with our top-notch Cloud Managed Services. Elevate your business with expert cloud solutions tailored for success." />
            </Helmet>
            <div className="title__banner all-clouds">
                <h2 className='main'>Cloud Managed <span className='title__small'>Services</span></h2>
                <Breadcrum parentLink="/services" parentName="Services" childLink="/services/cloud" childName="Cloud Managed Services"/>
            </div>

            <HeroBanner setActiveLink={setActiveLink} title="Cloud Managed Services " redTitle="Embrace the Future of Business with Confidence" banner={banner} stats={stats}
                btnText="Accelerate Your Business" desp="We help migrate your workloads on the cloud for digital transformation, supporting rehosting patterns, including mass migration, image migration, application migration, database rehosting, data migration, and storage migration. With us, you can"
            />

            <HoriBanner title="Cloud Managed " imgBg={cloud}
                horiCard={horiCards}
                titleDesp="Services " desp="NimbusBytes provides cloud-managed services for managing infrastructure, network, security, disaster recovery, containers, enterprise solutions, or all applications or data centers." />

            <HeroTabs imgBg={bubble} title="Why Choose NimbusBytes for " titleDesp="Cloud Managed Services
" desp="When it comes to cloud managed services, NimbusBytes is more than just a provider; We are your strategic partner to unlock unparalleled success. Here’s why talented businesses across the industries choose us. 
" horiTabs={serviceTabs} />

            {
                <SimpleBanner
                    btnText="Let's Connect" link="/contactus" setActiveLink={setActiveLink}
                    title="Get 30 mins" desp=" no-cost planning session with a cloud services specialist Whether you’re looking to develop a strategy, move to managed services, or continuously update and secure your applications and infrastructure, ask for free expert advice." />

            }
        </div>
    )
}

export default Cloud