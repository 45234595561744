import React from 'react'
import './cumuli.css'
import Breadcrum from '../../../components/Breadcrum/Breadcrum'
import MigrateCards from '../../../components/MigrateCards/MigrateCards'
import course1 from '../../../images/cumuli/course1.svg'
import course2 from '../../../images/cumuli/course2.svg'
import course3 from '../../../images/cumuli/course3.svg'
import course4 from '../../../images/cumuli/course4.svg'
import course5 from '../../../images/cumuli/course5.svg'
import course6 from '../../../images/cumuli/course6.svg'
import course7 from '../../../images/cumuli/course7.svg'
import course8 from '../../../images/cumuli/course8.svg'
import course9 from '../../../images/cumuli/course9.svg'
import course10 from '../../../images/cumuli/course10.svg'
import course11 from '../../../images/cumuli/course11.svg'
import course12 from '../../../images/cumuli/course12.svg'
import SecurityCards from '../../../components/SecurityCards/SecurityCards'
import Carousal from '../../../components/Carousal/Carousal'
import { Link } from 'react-router-dom'
import books from '../../../images/cumuli/books.jpg'
import logo from '../../../images/cumuli/cumuli.png'


const Cumuli = ({ setActiveLink }) => {

  const courseData = [
    {
      img: course1,
      title: "General Virtual Assistance",
      desp: "Learn essential skills for virtual assistants, including administrative tasks, customer support, and online tools to excel in a remote environment. ",
      link: "#"
    },
    {
      img: course2,
      title: "Graphic Design",
      desp: "Master the fundamentals of graphic design, including typography, color theory, and software tools like Adobe Creative Suite to create stunning visuals. ",
      link: ""
    },
    {
      img: course3,
      title: "Social Media Management & Content Creation",
      desp: "Develop expertise in managing social media channels and creating engaging content to boost brand visibility and engagement. ",
      link: ""
    },
    {
      img: course4,
      title: "Social Media Marketing & Advertisement",
      desp: "Learn strategies for successful social media campaigns and advertisements to maximize reach and ROI. ",
      link: ""
    },
    {
      img: course5,
      title: "Lead Generation",
      desp: "Understand the techniques to generate quality leads through various online and offline marketing strategies. ",
      link: ""
    },
    {
      img: course6,
      title: "Email Marketing & CRM",
      desp: "Learn to design effective email marketing campaigns and manage customer relationships to drive conversions and retention.  ",
      link: ""
    },
    {
      img: course7,
      title: "eCommerce",
      desp: "Get insights into eCommerce platforms, product management, digital payments, and customer service to grow an online business.  ",
      link: ""
    },
    {
      img: course8,
      title: "Web Development",
      desp: "Gain skills in front-end and back-end web development, including HTML, CSS, JavaScript, and other popular frameworks. ",
      link: ""
    },
    {
      img: course9,
      title: "Search Engine Optimization",
      desp: "Learn SEO techniques to enhance website visibility, improve search rankings, and drive organic traffic.  ",
      link: ""
    },
    {
      img: course10,
      title: "Mobile Development",
      desp: "Master mobile app development for iOS and Android, from design to deployment, using popular programming languages and frameworks. ",
      link: ""
    },
    {
      img: course11,
      title: "Data Analytics",
      desp: "Learn to analyze data, use statistical tools, and interpret results to make informed business decisions. ",
      link: ""
    },
    {
      img: course12,
      title: "Machine Learning & AI",
      desp: "Explore the fundamentals of machine learning and AI, including algorithms, data models, and real-world applications. ",
      link: ""
    },
  ]

  const trainings = [
    {
      img: course1, // Keep original as there's no match
      title: "Excellent Customer Service Training - The Hungry Pita",
      link: ""
    },
    {
      img: course5, // Replaced with matching course image for "Lead Generation"
      title: "Digital Marketing: A Modern Approach to Success - SJDM",
      link: ""
    },
    {
      img: course4, // Replaced with "Social Media Marketing & Advertisement" image
      title: "META Ads for Business Training - MOL-Magsaysay, Cavite",
      link: ""
    },
    {
      img: course1, // No match, updating title
      title:  "DJPH Bulacan Crafting of Online Marketing Tools for Businesses",
      link: ""
    },
    {
      img: course4, // Replaced with "Social Media Marketing & Advertisement" image
      title: "META Ads for Business Training - MOL-Magsaysay, Cavite",
      link: ""
    },
    {
      img: course7, // Replaced with "eCommerce" image
      title: "Digital Marketing and E-Commerce Course DICT-PESO Guiguinto",
      link: ""
    }
  ];

  const SliderData = [
    {
        img: logo,
        title: 'Cumuli: Digital Future Pathways',
        desp: "CUMULI: Digital Learning Pathways is a joint venture between Project: Creative Collab and Nimbusbytes, designed to empower the future generation. Through hands-on guidance from industry experts, CUMULI provides students with essential skills and knowledge for digital careers, preparing them for success in the global digital economy. Together, we aim to shape the leaders of tomorrow by providing access to cutting-edge resources and mentorship. ",
        image: "https://images.unsplash.com/photo-1518655048521-f130df041f66?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        button: <Link to="/contact"><button>Contact Us</button></Link>
    },
    {
      img: logo,
      title: 'Cumuli: Digital Future Pathways',
      desp: " CUMULI: Digital Learning Pathways is a joint venture between Project: Creative Collab and Nimbusbytes, designed to empower the future generation. Through hands-on guidance from industry experts, CUMULI provides students with essential skills and knowledge for digital careers, preparing them for success in the global digital economy. Together, we aim to shape the leaders of tomorrow by providing access to cutting-edge resources and mentorship. ",
      image: "https://images.unsplash.com/photo-1533628635777-112b2239b1c7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      button: <Link to="/contact"><button>Contact Us</button></Link>
    },
    {
      img: logo,
      title: 'Cumuli: Digital Future Pathways',
      desp: " CUMULI: Digital Learning Pathways is a joint venture between Project: Creative Collab and Nimbusbytes, designed to empower the future generation. Through hands-on guidance from industry experts, CUMULI provides students with essential skills and knowledge for digital careers, preparing them for success in the global digital economy. Together, we aim to shape the leaders of tomorrow by providing access to cutting-edge resources and mentorship. ",
      image: "https://images.unsplash.com/photo-1493606278519-11aa9f86e40a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      button: <Link to="/contact"><button>Contact Us</button></Link>
    }
  ]  
  



  return (
    <div className='cumuli'>

      {/* <div className="title__banner ">
        <h2 className='main'>Cumuli </h2>
        <Breadcrum setActiveLink={setActiveLink} parentLink="/projects" parentName="Projects" childLink="/projects/cumuli" childName="Cumuli" />
        <p> 
CUMULI: Digital Learning Pathways is a joint venture between Project: Creative Collab and Nimbusbytes, designed to empower the future generation. Through hands-on guidance from industry experts, CUMULI provides students with essential skills and knowledge for digital careers, preparing them for success in the global digital economy. Together, we aim to shape the leaders of tomorrow by providing access to cutting-edge resources and mentorship. </p>
      </div> */}

      <Carousal SliderData={SliderData}/>

      <MigrateCards title="Courses" desp="Explore a Diverse Range of Professional Courses
Our platform offers a variety of courses tailored to meet the demands of today's digital landscape. From mastering Virtual Assistance, Graphic Design, and Lead Generation to excelling in Social Media Marketing, Email Marketing, and Web Development, we cover it all. Whether you're looking to enhance your eCommerce skills or manage content creation, our programs are designed to help you thrive." cardData={courseData} />


      <SecurityCards title="Digital  " redTitle=" Marketing Services" desp="Onsite Digital Marketing Training & Partnerships
We offer transformative, onsite digital marketing training in collaboration with top businesses and institutions. From Customer Service 
Excellence to advanced META Ads and eCommerce, our sessions empower professionals with essential digital skills. Our partnerships 
include The Hungry Pita, DICT-PESO, and more. Looking forward to having digital transformation trainings with you and your team. Contact 
us to customize workshops that meet your specific needs!" setActiveLink={setActiveLink}
        securityCard={trainings} buttonDesp="Contact to get more information on the onsite seminars and training." />
    </div>
  )
}

export default Cumuli