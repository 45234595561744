import React from 'react'
import OurClients from '../../components/OurClients/OurClients'
import Testimonial from '../../components/Testimonial/Testimonial'
import ContactUs from '../../components/ContactUs/ContactUs'
import SimpleBanner from '../../components/SimpleBanner/SimpleBanner'
import Faqs from '../../components/Faqs/Faqs'
import './projects.css'
import MigrateCards from '../../components/MigrateCards/MigrateCards'
import CumuliILogo from '../../images/cumuli/cumuli.png'

const Projects = () => {

  let faqMap = [
    {
      title: "What is Information Technology (IT) Service?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
      title: "What is a Cloud Computing Service?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
      title: "What is Cybersecurity Service?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
      title: "What is a Data and Analytics Service?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
      title: "How Much does it cost to start with IT services?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
      title: "What is your pricing model?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
      title: "Which tools, technologies, and frameworks do you support?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
      title: "Who Is NimbusBytes?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
  ]

  var migrateCards = [
    {
      img: CumuliILogo,
      title: "Cumuli: Digital Future Pathways",
      desp: "Leverage our cloud-managed services, ensuring seamless operations and scalability. Our global team of experts manages the complexities of cloud infrastructure, allowing you to focus on innovation and growth.",
      link: "/projects/cumuli"
    },

  ]

  


  return (
    <div className='projects'>

      <div className="title__banner ">
        <h2 className='main'>Projects</h2>
      </div>

      <MigrateCards cardData={migrateCards} />


      <OurClients />

      <Testimonial />

      <Faqs faqMap={faqMap} title="FAQ" desp="Empower innovation and enhance your process workflow performance with our high-quality IT services." />

      <ContactUs />

      <SimpleBanner title1="Book Your" redText="FREE" topDesp="Discuss Your Needs."
        btnText="Let 's  Connect" title="30-Minute Consultation." link="/contactus" />

    </div>
  )
}

export default Projects