import React from 'react'
import './heroBanner.css'
import { Link } from 'react-router-dom';
import Breadcrum from '../Breadcrum/Breadcrum';
import ContactUsForm from '../ContactUsForm/ContactUsForm';

const HeroBanner = ({ banner, title, titleDesp, desp, subDesp, redTitle, stats,redBold ,btnText,isHome,isContact,isAbout,childName,childLink,link,setActiveLink}) => {

  console.log("herobanner");

  return (
    <div id='top'>
      <div className="hero__banner_wrapper">
        <div className="hero__banner">
          <div className="hero__banner_left">
          { (isAbout || isContact) && (<Breadcrum parentLink="/" parentName="Home" childLink={childLink} setActiveLink={setActiveLink} childName={childName}/>) }

            <h3 className='main'> <span className='redBold'>{redBold}</span> {title} <span className='title__small'>{titleDesp}</span><span className='redTitle'>{redTitle}</span></h3>
            <p>{desp}</p>
            <strong>{subDesp}</strong>
            {btnText && <Link onClick={() => {window.scrollTo(0,0) ;setActiveLink(link ? link : "/contactus") }} className='red-btn' to={link ? link : "/contactus"} > <span></span><span></span><span></span><span></span><span>{btnText}</span> </Link>}
          </div>
          <div className="hero__banner_right">

            {
              !isHome ? (
                <>
                <div className="all-stats">
              {stats && stats.map((ele, index) => (
                <div key={index} className={stats.length === 4 ? "stats__box" : "stats__box"} style={ele.styles ? ele.styles : {}}>
                  {ele.text} <span>{ele.stats}</span>
                </div>
              ))}
            </div>
            {banner && <img src={banner} alt="banner" />}
            </>
              ) : (
                  <>
                    <div className='banner__circle1'>
                    </div>
                    <div className='banner__circle2'>
                    </div>
                    <div className='banner__circle3'>
                    </div>
                    <div className="hero__banner_img"><img src={banner} alt="banner" /></div>
                    {/* <LottieSVG /> */}
                  </>
                )
            }

            {
              isContact && (
                <>
                <ContactUsForm />
                </>
              )
            }
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroBanner