import React from "react"

const BlueCircle = ({ top, right, left, bottom }) => {

    let styles = {
        content: '',
        position: "absolute",
        borderRadius: "297.181px",
        background: "rgba(0, 194, 255, 0.99)",
        filter: "blur(200px)",
        width: "297.181px",
        height: "297.181px",
        ...(top && { top }),
        ...(right && { right }),
        ...(left && { left }),
        ...(bottom && { bottom }),
    }

    return <div className="blueCircle" style={styles}></div>
}

export default BlueCircle;
