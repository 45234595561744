import React from 'react'
import './devops.css'
import banner from '../../../images/security-circle.png'
import HeroBanner from '../../../components/HeroBanner/HeroBanner'
import HoriBanner from '../../../components/HoriBanner/HoriBanner'
import SimpleBanner from '../../../components/SimpleBanner/SimpleBanner'
import MigrateCards from '../../../components/MigrateCards/MigrateCards'
import migrateAnal1 from '../../../images/migrate-anal-1.svg'
import Breadcrum from '../../../components/Breadcrum/Breadcrum';

import horiIcon1 from '../../../images/devOps/horiIcon1.svg'
import horiIcon2 from '../../../images/devOps/horiIcon2.svg'
import horiIcon3 from '../../../images/devOps/horiIcon3.svg'
import horiIcon4 from '../../../images/devOps/horiIcon4.svg'
import horiIcon5 from '../../../images/devOps/horiIcon5.svg'
import horiIcon6 from '../../../images/devOps/horiIcon6.svg'
import migrateComp from '../../../images/devOps/migrateComp.svg'
import { Helmet } from 'react-helmet'

const Devops = ({setActiveLink}) => {
  var horiCards = [
    {
      img: horiIcon1,
      title: "DevSecOps frameworks",
      desp: "The DevSecOps framework puts security testing at the heart of your ongoing integration process and enables end-to-end analysis of security flaws using our plugin developed for JIRA to enable secure deployments."
    },
    {
      img: horiIcon2,
      title: "Cloud Services and CloudOps",
      desp: "Put the right practices, tools, and automation in place to make cloud migration easier and more efficient, and projects include CloudOps, Cloud Automation, and Cloud DevSecOps."
    },
    {
      img: horiIcon3,
      title: "Application Lifecycle Management Modernization",
      desp: "Consultant on JIRA implementation is to enable effective use of ALM by outsourcing services such as JIRA Customization, JIRA Administration, and JIRA User Support."
    },

    {
      img: horiIcon4,
      title: "Domain Specific DevSecOps",
      desp: "The domain-specific DevSecOps framework helps with system lifecycle management, continuous integration, and GPU implementation for embedded systems software across major industry verticals such as medical devices, automotive, telecommunications, and more."
    },
    {
      img: horiIcon5,
      title: "SRE and Observability",
      desp: "Combining the capabilities of software and systems technologies to design and deploy large, highly distributed, fault-tolerant systems with an aim to ensure reliability and uptime, the projects include monitoring, cloud management, auto-treatment, and AIOps."
    },
    {
      img: horiIcon6,
      title: "Platform Engineering",
      desp: "Enhance your software development with our Platform Engineering expertise. We specialize in building design and flexible construction, ensuring flexibility and safety from the outset. Seamlessly integrate automation into your systems to increase efficiency and deploy with confidence using our robust CI/CD pipeline. Let us empower your DevSecOps journey with a secure, automated, and scalable foundation."
    },

  ]

  let stats = [
    { text: 'Reduction in manual efforts by ', stats: '80%' },
    { text: 'Reduction in application downtime by ', stats: '90%', styles: { marginTop: "auto" } },
    { text: 'Reduction in time spent on database administration activity by', stats: '75%' },
  ]

  const migrateCards = [
    {
      title: "Optimize Productivity",
      desp: "Leverage DevsecOps and SRE services from NimbusBytes and enjoy the benefits of business process assessments, concept recommendations, and architecture design.         ",
    },
    {
      title: "Achieve Automation Goals",
      desp: "Every business has different requirements for automation goals. Therefore, it is essential to partner with a team of experts offering the right commercial and open-source tools to meet your targets.      ",
    },
    {
      title: "Self-healing and Auto-scaling",
      desp: "As a DevSecOps implementation expert, we help you manage containerization, orchestrate infrastructure, and provide self-healing and auto-scaling as per your business needs."
    },
    {
      title: "Minimal Investment            ",
      desp: "Cost reduction or minimal investment is always the foremost need of any business when implementing technology strategies. With our DevSecOps, you can ensure your journey without the significant outlay upfront.      ",
    },
  ]

  console.log('software');


  return (
    <div className='devops'>

      <Helmet>
        <meta name='title' data-react-helmet="true" property="og:title" content="DevSecOps & SRE Services| NimbusBytes" />
        <meta name='description' data-react-helmet="true" property="og:description" content="Optimize development, security, and operations with our DevSecOps and SRE services. Achieve high performance and reliability in your software delivery." />
      </Helmet>

      <div className="title__banner all-clouds">
        <h2 className='main'>Dev<span className='redBold'>Sec</span>Ops and SRE  <span className='title__small'>Services</span></h2>
        <Breadcrum parentLink="/services" parentName="Services" childLink="/services/devops" childName=" DevSecOps and SRE Service" />
      </div>

      <HeroBanner setActiveLink={setActiveLink} subDesp="Liberate traditional practices and go to market faster as you discover a new world of business value and maximize your business costs with our DevSecOps and SRE services. With us, you can achieve" banner={banner} stats={stats} btnText="Accelerate Your Business"
      />

      <HoriBanner title="NimbusBytes DevSecOps Service Offerings " redTitle="" imgBg={migrateAnal1}
        horiCard={horiCards} desp="Our DevSecOps services are all about seeing the total value of your business, whether delivered to your customers, internally or elsewhere. By leveraging DevSecOps and SRE services, you'll be in a far better position to test and improve customer experience and get timely innovations faster, ensuring your business runs smoothly end-to-end." />

      <MigrateCards headImg={migrateComp} firstImg={migrateAnal1} title="Our Agile " redTitle="DevSecOps Approach" desp="Reduce time to market, automate valuable, time-consuming tasks, and reap profits by focusing single-mindedly on your business needs. By creating unique partnerships, you have is the ability to focus on what you do best – delivering value to your customers. Our expertise comes equipped with a full range of DevSecOps applications and can be customized." cardData={migrateCards} />

      <SimpleBanner title1="Book Your 30-Minute" redText="FREE" topDesp="Innovation Awaits!" link="/contactus" setActiveLink={setActiveLink}
        btnText="Let’s Collaborate" title=" Consultation Now." />

    </div>
  )
}

export default Devops