import React from 'react'

const Card = ({ data, current, currentCard ,handleMouseEnter, handleMouseLeave ,handleTouchStart,handleTouchEnd}) => {

  return (
    <div className={`card ${currentCard > current? "inactive" : ""}`} onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()} onTouchEnd={() => handleTouchEnd()} onTouchStart={() => handleTouchStart()}>
      <div className="card__img">{data.img? <img src={data.img} alt="profile" /> : ""}</div>
      <div className="card__desp">
        <p>{data.desp}</p>
        <h4>{data.name}</h4>
      </div>
    </div>
  )
}

export default Card