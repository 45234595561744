import React from 'react'
import './contactUs.css'
import ContactUsForm from '../ContactUsForm/ContactUsForm'
import Capsules from '../Capsules/Capsules'
import chall1 from '../../images/contactUs/contactUsImg.png'
import chall3 from '../../images/challengeIcon2.svg'
import chall2 from '../../images/security2.svg'
import { Link } from 'react-router-dom'

const ContactUs = ({isContact,scrollTop}) => {
  return (
    <div className="contactUs__wrapper">
    <div className='contactUs'>
        <div className="contactUs__left">
            <h3><span>Our Process.</span> Simple, Seamless, Streamlined.</h3>
            <div className="contactUs__cards">
                <div className="contactUs__card">
                    <div className="step">Step 1</div>
                    <h4><span>Join</span> Exploration Call</h4>
                    <p>On a discovery call, we will discuss your business in depth. Additionally, we will explore your team structure and approach, overall business goal, budget, and required skill sets to understand project needs.</p>
                </div>
                <div className="contactUs__card">
                    <div className="step">Step 2</div>
                    <h4><span>Discuss Solution</span> and Team Structure</h4>
                    <p>After understanding your project specifications and agreeing on an engagement model, We will email you to take your availability and schedule a call with a team of experts to discuss a plan of action and duration.</p>
                </div>
                <div className="contactUs__card">
                    <div className="step">Step 3</div>
                    <h4><span>Get Started</span> and Track Performance</h4>
                    <p>Once we are aligned on everything, we’ll start working on your project. Additionally, you will receive reports on progress and updates regularly. We will keep adapting your feedback to meet the end goal.</p>
                </div>
            </div>
           {isContact && <Link onClick={() => scrollTop() } to="" ><div className="red-btn"><span></span><span></span><span></span><span></span><span>Accelerate Your Business</span> </div></Link>} 
        </div>

        {isContact ? 
        (<Capsules capsule1Desp="Ensure Security Measure" capsule2Desp="Achieve High Retention Rate" img1={chall1} img2={chall2} img3={chall3}/>):(<ContactUsForm />)}
        
    </div>
    </div>
  )
}

export default ContactUs