import React from 'react'
import './analytics.css'
import banner from '../../../images/security-circle.png'
import HeroBanner from '../../../components/HeroBanner/HeroBanner'
import HoriBanner from '../../../components/HoriBanner/HoriBanner'
import HeroTabs from '../../../components/HeroTabs/HeroTabs'
import SimpleBanner from '../../../components/SimpleBanner/SimpleBanner'
import MigrateCards from '../../../components/MigrateCards/MigrateCards'
import DataChallanges from '../../../components/DataChallenges/DataChallanges'
import chall1 from '../../../images/challenge.png'
import chall3 from '../../../images/challengeIcon2.svg'
import chall2 from '../../../images/challengeIcon1.svg'
import analytics from '../../../images/analytics-title.svg';
import analBg from '../../../images/analytics-bubble.svg'
import chooseBg from '../../../images/analytics-choose.svg'
import migrateAnal2 from '../../../images/migrate-anal-2.svg'
import migrateAnal1 from '../../../images/migrate-anal-1.svg'
import Breadcrum from '../../../components/Breadcrum/Breadcrum'


import tab1 from '../../../images/analytics/tab1.svg'
import tab2 from '../../../images/analytics/tab2.svg'
import tab3 from '../../../images/analytics/tab3.svg'
import tab4 from '../../../images/analytics/tab4.svg'
import tab5 from '../../../images/analytics/tab5.svg'
import tab6 from '../../../images/analytics/tab6.svg'

import horiIcon1 from '../../../images/analytics/horiIcon1.svg'
import horiIcon2 from '../../../images/analytics/horiIcon2.svg'
import horiIcon3 from '../../../images/analytics/horiIcon3.svg'
import horiIcon4 from '../../../images/analytics/horiIcon4.svg'
import horiIcon5 from '../../../images/analytics/horiIcon5.svg'
import horiIcon6 from '../../../images/analytics/horiIcon6.svg'
import { Helmet } from 'react-helmet'

const Analytics = ({setActiveLink}) => {


    console.log('analytics');


    var horiCards = [
        {
            img: horiIcon1,
            title: "Advanced Machine Learning Integration",
            desp: "Leverage the power of cutting-edge machine learning to uncover hidden patterns in your data. Drive automation, improve processes, and make data-driven decisions that adapt and evolve with your business."
        },
        {
            img: horiIcon2,
            title: "Continuous Performance Monitoring",
            desp: "Implement continuous monitoring solutions to monitor the performance of your data systems. Identify bottlenecks, optimize processes, and ensure your data infrastructure always operates at peak efficiency."
        },
        {
            img: horiIcon3,
            title: "Cross-Platform Data Integration",
            desp: "Break down data silos by seamlessly integrating information from different platforms. Gain a holistic view of your data landscape while supporting better operations and increasing overall productivity."
        },

        {
            img: horiIcon4,
            title: "Data Consultation Services",
            desp: "Gain a competitive edge with our expert advisors who guide you to leverage data strategically. From identifying key performance indicators to developing a data-driven roadmap, we ensure your data works for you."
        },
        {
            img: horiIcon5,
            title: "Data Analytics Modernization",
            desp: "Improve your analytics capabilities with our modernization services. Nimbusbytes enhances existing data analytics solutions to maximize ROI and adapt to evolving business needs."
        },
        {
            img: horiIcon6,
            title: "Continuous Performance Monitoring",
            desp: "Implement continuous monitoring solutions to monitor the performance of your data systems. Identify bottlenecks, optimize processes, and ensure your data infrastructure always operates at peak efficiency."
        },

    ]

    var serviceTabs = [
        {
            img: tab2,
            title: "Proven Expertise",
            desp: "Take advantage of NimbusBytes’ extensive knowledge and experience. Our dedicated professionals bring foolproof expertise in the dynamic field of Data & Analytics."
        },
        {
            img: tab1,
            title: "Customized Solutions",
            desp: "Experience customized solutions tailored to your unique business needs. We understand that one size doesn’t fit all, and our services are designed to match your goals seamlessly."
        },
        {
            img: tab5,
            title: "Cutting-Edge Technology",
            desp: "Stay ahead of the latest technological developments. NimbusBytes combines state-of-the-art tools and information to ensure your business benefits from the most up-to-date Data & Analytics solutions."
        },
        {
            img: tab6,
            title: "Client-Centric Approach",
            desp: "Your success is our top priority. We focus on a customer-centric approach, working closely with each client to understand their challenges and aspirations and delivering solutions that move their business forward."
        },
        {
            img: tab4,
            title: "Data Security Assurance",
            desp: "Confidence is paramount. NimbusBytes puts the security of your data first and implements robust procedures to protect your information and ensure compliance with industry standards."
        },
        {
            img: tab3,
            title: "Transparent Processes",
            desp: "Enjoy the obvious at every stage. We believe in open communication and providing clear insight into our policies, ensuring you are informed and confident on your journey to data-driven success."
        },
    ]

    const migrateCards = [
        {
            desp: "Data Quality Assurance: NimbusBytes uses advanced tools and processes to ensure data reliability and integrity, reducing the complexity associated with data quality assurance.",
        },
        {
            desp: "We strategically optimize your analytics solution, focusing on extracting the most value from your stored data while addressing the challenges associated with maximizing ROI from it.",
        },
        {
            desp: "Our experts specialize in seamless data integration, using complex techniques to simplify and imaginatively integrate complex information with data from multiple sources to produce a one-of-a-kind result effortlessly.",
        },
        {
            desp: "NimbusBytes offers comprehensive training and support, filling the skills gaps in your team and empowering them to effectively use Data & Analytics tools.",
        },
        {
            desp: "NimbusBytes is a scalable solution that keeps pace with your growing data needs, ensuring your analytics system remains viable and efficient as your business expands.",
        },
        {
            desp: "With NimbusBytes, real-time analytics have become a reality. We implement systems with dynamic data transformation, ensuring timely and actionable insights.",
        },
        {
            desp: "Safety is at the heart of our industry. NimbusBytes implements strict security measures and ensures compliance with industry regulations to protect your data adequately.",
        },
        {
            desp: "NimbusBytes establishes strong data governance and standard practices, ensuring accuracy and reliability across all data types for improved decision-making.",
        },
        {
            desp:"Seamless integration of modern analytics solutions with legacy systems is a NimbusBytes product, ensuring a smooth transition and a concurrency of technologies"
        }
    ]

    const challengeCards = [
        {
            title: 'Implementing Real-time Analytics',
            desp: 'Implementing real-time analytics to keep pace with dynamic data transformation has been challenging, requiring complex systems and processes.'
        },
        {
            title: 'Data Governance and Standards',
            desp: 'Establishing effective data governance and standardization processes is a common challenge, affecting consistency and reliability across dataset types.'
        }, {
            title: 'Legacy System Integration',
            desp: 'Integrating modern analytics solutions into legacy systems can be challenging, requiring a variety of strategies to ensure seamless coexistence.'
        },
        {
            title: 'Data Privacy Concerns',
            desp: 'Managing data privacy concerns and complying with changing regulations pose ongoing challenges in the Data and analytics landscape.'
        },
        {
            title: 'Optimization of Optimized Technology',
            desp: 'Staying abreast of rapid technological advances is challenging, and organizations must constantly update their assessment processes.'
        },
        {
            title: 'Balancing Complexity and Simplicity',
            desp: 'Striking a balance between advanced analytical capabilities and user-friendly interfaces is a challenge in dealing with technical and non-technical stakeholders.'
        }
    ]

    let stats = [
        { text: 'Increase Data Security', stats: '30%' },
        { text: 'Boost Decision Velocity by', stats: '25%' },
        { text: 'Amplify Revenue Streams by', stats: '18%' },
        { text: 'Boost Cost-efficiencies by ', stats: '15%' },
    ]

    return (
        <div className='analtics'>

            <Helmet>
                <meta name="title" property="og:title" content="Data and Analytics Services | NimbusBytes" data-react-helmet="true" />
                <meta name='description' property="og:description" content="Leverages the power of data with our comprehensive Data and Analytics services. Drive informed decisions and achieve unparalleled insights for your business." data-react-helmet="true" />
            </Helmet>

            <div className="title__banner all-analytics">
                <h2 className='main'>  Data <span className='redTitle'> &</span>  Analytics</h2>
                <Breadcrum parentLink="/services" parentName="Services" childLink="/services/analytics" childName="Data & Analytics"/>
            </div>

            <HeroBanner setActiveLink={setActiveLink} title="Redefine Your Business Journey With" redTitle="Data & Analytics Services" banner={banner}
                desp="Build your data, supercharge decisions, and reduce costs effortlessly. Our Data & Analytics services help you identify new revenue streams and leverage your untapped business talent. Ready to change your success story?  Click to transform the way you do business! With us, you can:"
                stats={stats} btnText="Accelerate Your Business"
            />

            <HoriBanner title="Data & Analytics" redTitle="Services" imgBg={analBg}
                horiCard={horiCards} desp="Nimbusbytes’ analytics services and solutions can help an organization expand and distinguish itself from the competition." />

            <HeroTabs imgBg={chooseBg} title="Why Choose NimbusBytes for" redTitle="Data & Analytics Services" desp="Choose NimbusBytes as your trusted partner in Data & Analytics, where excellence meets innovation for unparalleled business flexibility." horiTabs={serviceTabs} />

            <DataChallanges title="Data & Analytics " redTitle="Challenges" challengeCards={challengeCards} img1={chall1}
                img2={chall2} img3={chall3} capsule1Desp="Increase uptime of your data" capsule2Desp="connect the right data to the right user" />

            <MigrateCards headImg={analytics} firstImg={migrateAnal1} secondImg={migrateAnal2} title="How NimbusBytes Mitigates" redTitle="Data & Analytics Challenges" cardData={migrateCards} />

            <SimpleBanner
            link="/contactus"
                title1="Let's Talk"
                topDesp="Ready to transform your data"
                btnText="Let 's  Connect"
                title="Schedule a 30 mins Free Call" challengeCards={challengeCards} />
        </div>
    )
}

export default Analytics