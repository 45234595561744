import React from 'react'
import './NotFoundPage.css'
import notFound from '../../images/404.svg';

const NotFoundPage = () => {
  return (
    <div className='notFound'><img src={notFound} alt="" /></div>
  )
}

export default NotFoundPage