import React, { useEffect, useRef, useState } from 'react'
import './horiBanner.css'
import Gradient from '../UI/Gradient';

const HoriBanner = ({ title, titleDesp, desp, horiCard, redTitle, imgBg }) => {

  const horiBannerRightRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isUserScroll, setIsUserScroll] = useState(false);

  const handleTabClick = (index) => {
    setIsUserScroll(true); // Set to true when user initiates scroll
    setActiveTab(index);

    // Scroll the corresponding card into view
    const cardElements = document.querySelectorAll('.hori__card');
    if (cardElements[index]) {
      const selectedCardRect = cardElements[index].getBoundingClientRect();
      const bannerRightRect = horiBannerRightRef.current.getBoundingClientRect();

      if (selectedCardRect.left < bannerRightRect.left) {
        // Scroll only if the selected card is to the left of the current view
        horiBannerRightRef.current.scrollBy({
          left: selectedCardRect.left - bannerRightRect.left,
          behavior: 'smooth',
        });
      }
    }

    // Reset isUserScroll after a short delay
    if (window.innerWidth > 767) {
      setTimeout(() => {
        setIsUserScroll(false);
      }, 100);
    }
  };

  useEffect(() => {
    if (window.innerWidth > 767) {
      const handleScroll = () => {
        if (!isUserScroll) {
          const cardElements = document.querySelectorAll('.hori__card');
          cardElements.forEach((card, index) => {
            const rect = card.getBoundingClientRect();
            if (rect.top - 100 >= 0 && rect.bottom + 100 <= window.innerHeight) {
              setActiveTab(index);
            }
          });
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isUserScroll]);


  return (
    <div className="hori__banner_wrapper">
      <div className="hori__banner">
        <div className="hori__banner_left">
          <h3 className='main'>{title}{titleDesp ? <span className='title__small'>{titleDesp}</span> : ''} {redTitle ? <span className='redTitle'>{redTitle}</span> : ''}</h3>
          <p>{desp}</p>

          <ul>
            {
              horiCard.map((data, index) => (
                <li key={index} className={index === activeTab ? ' active__link' : ''} onClick={() => handleTabClick(index)}>{data.title}</li>
              ))
            }
          </ul>
          <img src={imgBg} className='clouds' alt="nimbusbytes" />

        </div>
        <div className="hori__banner_right" ref={horiBannerRightRef}>
          {
            horiCard.map((data, index) => (
              <div key={index} className="hori__card">
                <div className="hori__card-img">
                <img src={data.img} alt="demo" />
                </div>
                <div className="hori__content">
                  <h3 className={(index === activeTab && window.innerWidth > 767) ? ' active__link' : ''}>{data.title}</h3>
                  <p>{data.desp}</p>
                </div>
              </div>
            ))
          }
          <Gradient width={200} height={200} top={0} left="30%" />
          <Gradient width={200} height={200} top={200} left="30%"/>
          <Gradient width={200} height={200} top={400} left="30%" />
          <Gradient width={200} height={200} top={600} left="30%" />
          <Gradient width={200} height={200} top={800} left="30%"/>
          <Gradient width={200} height={200} top={1000} left="30%" />
          <Gradient width={200} height={200} top={1200} left="30%"/>

        </div>
      </div>

    </div>
  )
}

export default HoriBanner