import React from 'react'
import './simpleBanner.css'
import backgroundBg from '../../images/simplebanner1.svg';
import { Link } from 'react-router-dom';


const SimpleBanner = ({ title1, redText, title, desp, btnText, topDesp, link, btnText2, link2,scrollTop ,setActiveLink}) => {

  console.log('SimpleBanner');

  return (
    <div>
      <div className="simple__banner">
        <img className='simple-banner-bg' src={backgroundBg} alt='test' />

        <div className="container">
          <div className="simple__left">
            {topDesp ? <p className={title1 && 'red-text'}>{topDesp}</p> : ''}
            {title1 ? <h3>{title1}<span className={title1 && 'red-text'}> {redText}</span></h3> : ''}
            <h3>{title}</h3>
            {desp ? <p>{desp}</p> : ''}
          </div>
          <div className="simple__right">
          {
              btnText2 ? <Link onClick={() => {window.scrollTo(0,0) ; setActiveLink('/services')}  } to={link2} className='contact-btn'>{btnText2}</Link> : ''
            }
            {
              btnText ? <Link className='red-btn' onClick={() => {scrollTop ? scrollTop() : window.scrollTo(0,0) ; setActiveLink(link)} } to={link}>{btnText}</Link> : ''
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimpleBanner