import React from 'react'
import Faqs from '../../components/Faqs/Faqs';
import ContactUs from '../../components/ContactUs/ContactUs';
import { FaQuestion } from "react-icons/fa6";
import './faq.css';
import SimpleBanner from '../../components/SimpleBanner/SimpleBanner';
import Breadcrum from '../../components/Breadcrum/Breadcrum';

const FAQ = ({setActiveLink}) => {

    let faqMap = [
        {
            title: 'What services does NimbusBytes offer?',
            des: 'NimbusBytes is your one-stop solution for various IT services, including cloud computing, cybersecurity, data analytics, software development, and migration/modernization. Our customized range of IT services rigorously meets the unique needs of different businesses in various sectors.            '
        },
        {
            title: "How can NimbusBytes benefit my business, even if I'm starting?",
            des: 'Whether you are a startup or an established business, NimbusBytes offers scalable solutions designed to improve growth and productivity. Our innovative approach ensures that companies can leverage cutting-edge technology to compete in the digital landscape regardless of size.'
        },
        {
            title: 'What sets NimbusBytes apart in the IT industry?',
            des: "NimbusBytes is distinguished by our customer-centric philosophy, technical expertise, and commitment to delivering consistently high-quality solutions. We believe in understanding each client's unique needs and tailoring our service to exceed expectations.            "
        },
        {
            title: 'Is NimbusBytes suitable for small businesses or startups?',
            des: 'Absolutely. NimbusBytes caters to the needs of businesses of all sizes, providing scalable solutions. Whether a small startup or a large enterprise, our services are tailored to match your needs, ensuring you get the right IT solution for your business.            '
        },
        {
            title: 'How does NimbusBytes ensure data security and privacy?',
            des: 'NimbusBytes puts the security and privacy of your data first. Our robust cybersecurity programs include encryption, access security, and ongoing analytics to protect your digital assets from evolving threats.            '
        },
        {
            title: 'What is the process for engaging with NimbusBytes for IT services?',
            des: 'What is the process for engaging with NimbusBytes for IT services? '
        },
        {
            title: 'Can NimbusBytes handle industry-specific IT requirements?',
            des: "Yes, NimbusBytes specializes in meeting industry-specific IT needs. Our solutions evolve to meet businesses' unique challenges and needs, ensuring quality and effective results.       "
        },
        {
            title: 'What is the expected timeline for project completion?',
            des: "It depends on the project stage, complexity, and requirements. However, NimbusBytes is committed to providing practical solutions without compromising quality. We work rigorously to meet and exceed our client's expectations in a reasonable amount of time.            "
        },
        {
            title: 'How can I get in touch with NimbusBytes for more information?',
            des: 'Feel free to contact NimbusBytes for more information or questions. Email us at [Contact Email] or use our online contact form on the website. We are here to help you quickly.            '
        },
        {
            title: 'Does NimbusBytes offer ongoing support after project completion?',
            des: 'Yes, NimbusBytes values ​​long-term partnerships. We provide ongoing support, maintenance, and operational opportunities after project completion to ensure the continued success of your IT solution.            '
        },
        {
            title: 'What technology frameworks do NimbusBytes support in software development?',
            des: 'NimbusBytes is device-agnostic and proficient in industrial systems. This versatility allows us to adapt to your needs, ensuring our software developments align closely with your business goals.            '
        },
        {
            title: 'Can NimbusBytes assist in cloud migration and modernization strategies?',
            des: 'Absolutely. NimbusBytes specializes in seamless cloud migration and modern infrastructure. Our IT expert guidance ensures a smooth transition to the latest technologies and optimizes your business for the digital future.             '
        },
        {
            title: 'How does NimbusBytes approach cybersecurity for eCommerce businesses? ',
            des: 'NimbusBytes prepares cybersecurity solutions for e-commerce, secures online transactions, and protects customer data. Our approach ensures a reliable and secure online shopping experience for your customers.             '
        },
        {
            title: 'What steps is NimbusBytes taking to improve data analytics for businesses? ',
            des: 'NimbusBytes optimizes data analytics using advanced techniques, giving businesses actionable insights. Our solutions enable informed decision-making and maintain a competitive edge in your industry.             '
        },
        {
            title: 'Can NimbusBytes help develop e-learning technology solutions? ',
            des: 'Sure. NimbusBytes supports the e-learning sector by providing IT solutions that enhance the e-learning experience. Our solutions ensure safe and innovative educational technology and improve the learning environment.             '
        },
        {
            title: 'How does NimbusBytes ensure confidentiality during software development? ',
            des: 'NimbusBytes maintains strict confidentiality during software development. This includes secure coding practices, confidentiality agreements, and controlled development to protect sensitive information.            '
        },
        {
            title: 'Does NimbusBytes provide training to clients on deployed IT solutions?',
            des: 'Yes, NimbusBytes offers training upon request. These sessions ensure a smooth transition and comprehensive understanding of the implemented IT solutions and empower your team to optimize their implementation.             '
        },
        {
            title: 'What businesses has NimbusBytes served well in past projects? ',
            des: 'Nimbusbytes has successfully served industries such as fintech, healthcare, e-commerce, education, travel and lifestyle. Our experience spans across sectors, reflecting our flexibility and expertise.             '
        },
        {
            title: 'Can NimbusBytes tailor IT solutions to specific business models? ',
            des: "Yes. NimbusBytes excels in providing customized IT solutions tailored to each person's specific business processes and needs. Our solutions are designed to integrate with your business processes seamlessly.             "
        },
        {
            title: 'How can NimbusBytes ensure a smooth transition during a cloud migration? ',
            des: 'NimbusBytes follows a careful cloud migration strategy, minimizing downtime and proactively addressing potential complications. Our approach ensures a smooth transition by improving efficiency during and after migration.             '
        },
        {
            title: 'What Cybersecurity measures does NimbusBytes implement for financial institutions? ',
            des: 'NimbusBytes implements strong cybersecurity measures for financial institutions, including encryption and security controls. Our custom IT solutions ensure the highest level of security for financial data and services.            '
        },
        {
            title: 'How does NimbusBytes keep up with industry trends and latest technologies? ',
            des: 'NimbusBytes maintains a culture of continuous learning. We invest in research and development, attend industry events, and promote partnerships to stay ahead of technology trends and ensure our customers benefit from the latest innovations.             '
        },
        {
            title: 'Can NimbusBytes provide references or case studies from previous projects? ',
            des: 'Yes, NimbusBytes can provide references and case studies upon request. These measures reflect successful project outcomes and customer satisfaction and provide insight into our capabilities and achievements.             '
        },
        {
            title: 'What support options are available for customers in different seasons? ',
            des: 'NimbusBytes provides global support through channels available through email and online communication platforms. This ensures that customers are accessible at different times and that support is provided promptly whenever needed.             '
        },
        {
            title: 'How does NimbusBytes approach software testing and quality assurance?',
            des: 'NimbusBytes places great emphasis on software testing and quality assurance. Our team follows industry best practices to deliver reliable, high-performance software solutions that meet exacting standards.             '
        },
        {
            title: 'Does NimbusBytes help with the adoption of technology and the training of client teams? ',
            des: 'Yes, NimbusBytes assists with technology adoption, including training, for customer groups. We aim to ensure a seamless transition and effective implementation of IT solutions and empower your team with the necessary skills.            '
        },
        {
            title: 'Can NimbusBytes help with digital marketing strategies? ',
            des: 'Yes. Nimbusbytes offers digital marketing strategies as part of its services. By leveraging technology and data analytics, we increase online visibility and customer engagement, helping your business thrive in a digital environment.             '
        },
        {
            title: 'How can NimbusBytes ensure data integrity during software development? ',
            des: 'NimbusBytes ensures data integrity through secure coding practices, version control, and rigorous testing during software development. This guarantee reduces the risk of data corruption or loss and makes your software solution more reliable.             '
        },
        {
            title: 'What analytics solutions does NimbusBytes offer? ',
            des: "NimbusBytes offers a variety of analytics solutions, including descriptive, analytical, predictive, and descriptive analytics. These solutions are designed to meet everyone's unique needs, providing valuable insights for informed decision-making.             "
        },
        {
            title: 'Can NimbusBytes help develop mobile applications for different platforms?  ',
            des: "Yes, NimbusBytes specializes in mobile application development for various platforms. Our expertise ensures the creation of user-friendly, functional, and feature-rich mobile solutions that align with your business goals.            "
        },
    ]



    return (
        <div className='faq-page'>
            <div className="title__banner ">
            <div className="que que1"><FaQuestion /></div>
            <div className="que que2"><FaQuestion /></div>
            <div className="que que3"><FaQuestion /></div>
            <div className="que que4"><FaQuestion /></div>
            <div className="que que5"><FaQuestion /></div>
                <h2 className='main'>FAQ</h2>
                <Breadcrum parentLink="/" setActiveLink={setActiveLink} parentName="Home" childLink="/faq" childName="FAQ"/>
            </div>

            <Faqs faqMap={faqMap}/>

            <ContactUs />

            <SimpleBanner title1="Book Your" redText="FREE" topDesp="Discuss Your Needs." setActiveLink={setActiveLink}
        btnText="Let 's  Connect" title="30-Minute Consultation." link="/contactus" />

        </div>
    )
}

export default FAQ