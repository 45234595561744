import React from 'react'
import './LegalNotice.css';
import Breadcrum from '../../components/Breadcrum/Breadcrum';
import DataChallanges from '../../components/DataChallenges/DataChallanges';
import SimpleBanner from '../../components/SimpleBanner/SimpleBanner';

const LegalNotice = ({setActiveLink}) => {

  const challengeCards=[
    {
      title:"User Information Collection",
      desp: "Our website comprehensively collects personal information, including but not limited to names, email addresses, browsing habits, location data, and device-specific information. This collection occurs through direct user inputs in forms, cookies, browsing history analysis, and through the use of third-party analytics tools. The purpose of this data collection is to understand user preferences, improve website functionality, and tailor our services to better meet your needs."
    },
    {
      title:"Use of Collected Information",
      desp: "The personal information we collect is utilized to enhance user experience, personalize content, provide targeted marketing, and improve customer service. We also use this data for internal analytics to understand user demographics, interests, and behavior, which helps us in making informed business decisions and developing new strategies for user engagement."
    },
    {
      title:"Data Sharing and Disclosure",
      desp: "Your personal information may be shared with trusted third parties, such as marketing partners, affiliates, and service providers, who assist us in operating our website and conducting our business. We also disclose information when legally required, such as in response to a court order or government request. Rest assured, any data sharing is conducted under strict confidentiality agreements and is limited to what is necessary for the specified purpose."
    },
    {
      title:"Data Security Measures",
      desp: "We prioritize the security of your personal data. Our website uses industry-standard encryption and security protocols to protect your information from unauthorized access, disclosure, alteration, or destruction. We regularly update our security practices and conduct periodic audits to ensure the highest level of data protection."
    },
    {
      title:"User Rights and Control",
      desp: "You have full control over your personal information on our website. This includes the right to access your data, request corrections, ask for data deletion, or object to certain uses of your data. We provide easy-to-use tools for you to exercise these rights. Your privacy choices are respected and implemented promptly upon request."
    },
    {
      title:"Cookies and Tracking Technologies",
      desp: "Our website employs cookies and other tracking technologies to enhance your browsing experience, understand site usage, and offer content relevant to your interests. Detailed information about the types of cookies used, their purpose, and how you can manage or disable them is provided. We respect your choices regarding cookies and will obtain your consent where required."
    },

    {
      title:"International Data Transfers",
      desp: "Given our global operations, we may transfer and process your personal data in countries other than your own. We ensure these transfers comply with international data protection laws and maintain data security. Your data is handled with the utmost care, regardless of where it is processed.Given our global operations, we may transfer and process your personal data in countries other than your own. We ensure these transfers comply with international data protection laws and maintain data security. Your data is handled with the utmost care, regardless of where it is processed."
    },
    {
      title:"Policy Updates and Changes",
      desp: "Our privacy policy may undergo changes to reflect new legal requirements, changes in our business practices, or user feedback. We commit to keeping you informed of any significant changes through clear, timely communication on our website and, where appropriate, direct notification."
    },
    {
      title:"Contact Information for Privacy Concerns",
      desp: "For any inquiries or concerns about your privacy and personal data on our website, please contact us at care[@]nimbusbytes.com . We are dedicated to promptly addressing your questions and concerns, ensuring a transparent and respectful dialogue."
    },
    {
      title:"Compliance with Laws and Regulations",
      desp: "We are fully committed to complying with all applicable privacy laws and regulations, including the General Data Protection Regulation (GDPR), the California Consumer Privacy Act (CCPA), and others. Our practices are regularly reviewed and updated to stay aligned with these legal standards, ensuring the highest level of privacy and data protection for our users."
    },
  ]
  return (
    <div className='privacy'>
            <div className="title__banner privacy">
                <h2 className='main'>  Legal <span className='redTitle'> Notice</span></h2>
                <Breadcrum setActiveLink={setActiveLink} parentLink="/" parentName="Home" childLink="/legal-notice" childName="Legal Notice"/>
            </div>

            <DataChallanges desp="Welcome to our Privacy Policy page! When you use our web site services, you trust us with your information. This Privacy Policy is meant to help you understand what data we collect, why we collect it, and what we do with it. This is important; we hope you will take time to read it carefully. Remember, you can find controls to manage your information and protect your privacy and security." challengeCards={challengeCards} />
    
            <SimpleBanner link="mailto:care@nimbusbytes.com" title="If you have any questions about this Privacy Policy, please contact us: "
        btnText="care@nimbusbytes.com"  />
    </div>
  )
}

export default LegalNotice