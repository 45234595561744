import React from 'react'
import './capsules.css'

const Capsules = ({img1,img2,img3,capsule1Title,capsule2Title,capsule1Desp,capsule2Desp}) => {
    return (
        <div className="data__container_right">
            <div className="data__img_left">
                <img src={img1} alt="nimbusbytes" />
            </div>
            <div className="data__img_right">
                <div className="capsule">
                <div className="capsule__content">
                                    <h4 className='capsule__title'> {capsule1Title ? capsule1Title : ""} </h4>
                                    <p className='capsule__desp'> {capsule1Desp ? capsule1Desp : ""}</p>
                                </div>
                    <img className='img' src={img2} alt="nimbusbytes" />
                </div>
                <div className="capsule">
                    <img className='img' src={img3} alt="nimbusbytes" />
                    <div className="capsule__content">
                    <h4 className='capsule__title'> {capsule2Title ? capsule2Title : ""}</h4>
                                    <p className='capsule__desp'>{capsule2Desp ? capsule2Desp : ""}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Capsules