import React, { useState, useRef } from 'react';
import './faq.css';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Gradient, { IconsGradient } from '../UI/Gradient';
import faqIcon from '../../images/faqIcon.svg';

const Faqs = ({ faqMap, title, desp }) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const descriptionRefs = useRef([]);

    const toggleDescription = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };


    const handleDescriptionHeight = (index) => {
        const descriptionRef = descriptionRefs.current[index];
        if (descriptionRef) {
            return activeIndex === index ? `${descriptionRef.scrollHeight}px` : '0';
        }
        return 'auto';
    };

    const handleMargin = (index) => {
        const descriptionRef = descriptionRefs.current[index];
        if (descriptionRef) {
            return activeIndex === index ? `10px` : '0';
        }
        return 'auto';
    };

    return (
        <div className='faq'>
            <div className="que que6"><img  src={faqIcon} alt="icon" /></div>
            <div className="container">
                {
                    title && (
                        <div className="faq__title">
                            <h3>{title}</h3>
                            <p>{desp}</p>
                        </div>
                    )
                }
                <div className="faq__container">
                    <Gradient width={200} height={200} top="0%" left="0%" />
                    <Gradient width={200} height={200} top="40%" left="10%" />
                    <Gradient width={200} height={200} top="80%" left="0%" />
                    <IconsGradient right="0" left="30%" top="0" rotate="310deg" />
                    {faqMap.map((ele, index) => (
                        <div key={index} className={activeIndex ? "faq__card h100" :  "faq__card "} onClick={() => toggleDescription(index)}>
                            <div className="faq__card_container">
                                <h4 className="faq__card_title"><span>{index + 1 + ". "}</span> <span>{ele.title}</span></h4>
                                <p ref={el => descriptionRefs.current[index] = el} className="faq__card_desp" style={{ maxHeight: handleDescriptionHeight(index) , marginTop: handleMargin(index) }}>{ele.des}</p>
                            </div>

                            <div className={activeIndex === index ? `faq__card_arrow active` : `faq__card_arrow`}>
                                <MdOutlineKeyboardArrowRight />
                            </div>
                        </div>
                    ))}
                    <Gradient width={200} height={200} top="0%" right="0%" />
                    <Gradient width={200} height={200} top="40%" right="10%" />
                    <Gradient width={200} height={200} top="80%" right="0%" />
                    {/* <IconsGradient right="0%" top="50%" rotate="210deg" /> */}
                </div>
            </div>
        </div>
    );
};

export default Faqs;
