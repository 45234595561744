import React from 'react'
import './ourSpecialities.css';
import { Link } from 'react-router-dom';
import Gradient, { IconsGradient } from '../UI/Gradient';

const Card = ({data}) => {
    return(
        <>
            <div className="OurSpecialities__card">
                <h4>{data.title}</h4>
                <p>{data.desp}</p>
            </div>
        </>
    )
}

const OurSpecialities = ({setActiveLink}) => {

    let cards = [
        {
            title: "Digital Experts",
            desp: "We are architects of seamless digital experiences, reshaping the way businesses navigate today's landscape."
        },
        {
            title: "Round-the-Clock Availability",
            desp: "At NimbusBytes, we understand the importance of time. Our team is available round the clock, ensuring support and solutions whenever you need them."
        },
        {
            title: "Cloud Mastery",
            desp: "Unlock the potential of scalable and robust cloud solutions, ensuring your business operates optimally, efficiently, and flexibly."
        },
        {
            title: "Quality at Speed",
            desp: "Experience efficiency without compromise. Our commitment to providing superior solutions is matched only by the way we bring them to life."
        },
        {
            title: "Cybersecurity Excellence",
            desp: "Our cybersecurity expertise ensures the integrity and privacy of your digital assets from ongoing threats."
        },
        {
            title: "Agile Development",
            desp: "Imagine a fast software solution and your ideas. Our agile development process perfectly tailors each solution to your business's unique vision."
        },
        {
            title: "Customized Excellence",
            desp: "Your success is exceptional, and so are our solutions. Tailored to your specific needs ensures that NimbusBytes Excellence is perfectly aligned with your business goals. "
        },
        {
            title: "Tool Agnostic Experts",
            desp: "We are swept up in a variety of devices and technologies. Our expertise extends beyond typical platforms, allowing us to be versatile and adaptable to the evolving needs of your business."
        }
    ]

    return (
        <div className="OurSpecialities__wrapper">
        <div className='OurSpecialities'>
            <div className="OurSpecialities__title">
                <div className="OurSpecialities__title-lhs">
                    <h3>Our Specialities</h3>
                    <div className="subTitle"><span>Elevate Your Business With NimbusBytes </span>
                        Where our expertise becomes the innovation that drives you forward.</div>
                </div>

               
                <Link onClick={() => {window.scrollTo(0,0); setActiveLink('/contactus')} } className="red-btn" to="/contactus" ><span></span><span></span><span></span><span></span><span>Accelerate Your Business</span> </Link>
                
            </div>

            <div className="OurSpecialities__container">
            <Gradient width={200} height={200} top={0} left="0%" />
                    <Gradient width={200} height={200} top={600} left="10%" />
                    <Gradient width={200} height={200} top={800} left="0%" />
                    <IconsGradient right="30%" left="30%" top="30%" rotate="310deg"  />
                {
                    cards.map((data,index)=>(
                        <Card key={index} data={data} />
                    ))
                }
                 <Gradient width={200} height={200} top={0} right="0%" />
                    <Gradient width={200} height={200} top={600} right="10%" />
                    <Gradient width={200} height={200} top={900} right="0%" />
                    <IconsGradient right="30%" top="30%" rotate="310deg" />
            </div>
            </div>
        </div>
    )
}

export default OurSpecialities