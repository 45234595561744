import React from 'react'
import './aboutus.css'
import HeroBanner from '../../components/HeroBanner/HeroBanner'
import SecurityCards from '../../components/SecurityCards/SecurityCards'
import OurClients from '../../components/OurClients/OurClients'
import Faqs from '../../components/Faqs/Faqs'
import SimpleBanner from '../../components/SimpleBanner/SimpleBanner'
import ContactUs from '../../components/ContactUs/ContactUs'
import Testimonial from '../../components/Testimonial/Testimonial'
import OurSpecialities from '../../components/OurSpecialities/OurSpecialities'
import banner from '../../images/Rocket.svg';

import story1 from '../../images/aboutus/story1.svg'
import story2 from '../../images/aboutus/story2.svg'
import story3 from '../../images/aboutus/story3.svg'

const AboutUs = ({setActiveLink}) => {

  let faqMap = [
    {
        title:"What is Information Technology (IT) Service?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
        title:"What is a Cloud Computing Service?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
        title:"What is Cybersecurity Service?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
        title:"What is a Data and Analytics Service?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
        title:"How Much does it cost to start with IT services?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
        title:"What is your pricing model?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
        title:"Which tools, technologies, and frameworks do you support?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
        title:"Who Is NimbusBytes?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
]
  

    var horiCards = [
        {
          img: story1,
          title: "The Beginning",
          desp: "In 2020, NimbusBytes started as a simple, small seed idea in the IT services landscape. We dreamed of changing how companies use technology. It was a time filled with passion, hard work, and a strong belief that something great was about to happen."
        },
        {
          img: story2,
          title: "Finding Our Fleet and Upward",
          desp: "Soon, we found our team - dedicated individuals ready to tackle challenges. We've learned and grown together, especially in areas like cloud computing and cybersecurity. Nimbusbytes was on the rise and became a stronghold in the tech world for providing robust IT services."
        },
        {
          img: story3,
          title: "Today",
          desp: "Nimbusbytes now stands tall as a reliable force in technology. Our journey has been about adapting and growing. Through a variety of projects and a great team, we have successfully completed 80+ projects, bringing smiles to 56+ happy clients worldwide. We're here to stay on the front lines, doing the tech work for you."
        }
    
      ]

  return (
    <div className='about'>
         <HeroBanner redBold="" title="Discover Our Story "  redTitle="The Vision and Values of NimbusBytes" 
            desp=" Founded in 2020, NimbusBytes is recognized as a leading global market player offering diverse IT services and solutions across industries and geographies. Guided by values of 'Integrity, Creativity, Excellence,' we've carved a unique niche in different segments of the industries. Our journey, marked by 'groundbreaking solutions and enduring customer relationships,' reflects our commitment to excellence and sustainable growth." 
            subDesp="Join us in shaping a future where 'every challenge becomes an opportunity for innovation.'" setActiveLink={setActiveLink}
            banner={banner} childLink="/about" childName="About Us" isAbout={true}
        />

        <div className="security__wrapper">
        <SecurityCards title="Our Story "  setActiveLink={setActiveLink}
            securityCard={horiCards} desp="" />
        </div>

        <OurSpecialities setActiveLink={setActiveLink}/>

        <OurClients />

        <Testimonial />

        <Faqs faqMap={faqMap} title="FAQ" desp="Empower innovation and enhance your process workflow performance with our high-quality IT services." />

        <ContactUs />

        <SimpleBanner title1="Book Your" redText="FREE" topDesp="Discuss Your Needs." link="/contactus" setActiveLink={setActiveLink}
        btnText="Let 's  Connect" title="30 Minute Consultation." />

        
    </div>
  )
}

export default AboutUs