import React from 'react'
import './cyberSecurity.css'
import banner from '../../../images/security-circle.png'
import HeroBanner from '../../../components/HeroBanner/HeroBanner'
import HeroTabs from '../../../components/HeroTabs/HeroTabs'
import SimpleBanner from '../../../components/SimpleBanner/SimpleBanner'
import MigrateCards from '../../../components/MigrateCards/MigrateCards'
import DataChallanges from '../../../components/DataChallenges/DataChallanges'
import chall1 from '../../../images/security1.png'
import chall3 from '../../../images/challengeIcon2.svg'
import chall2 from '../../../images/security2.svg'
import SecurityCards from '../../../components/SecurityCards/SecurityCards'
import securityImg from '../../../images/security-title.png';
import lockBg from '../../../images/lockBg.svg'
import Breadcrum from '../../../components/Breadcrum/Breadcrum'

import tab1 from '../../../images/security/tab1.svg'
import tab2 from '../../../images/security/tab2.svg'
import tab3 from '../../../images/security/tab3.svg'
import tab4 from '../../../images/security/tab4.svg'
import tab5 from '../../../images/security/tab5.svg'
import tab6 from '../../../images/security/tab6.svg'

import security1 from '../../../images/security/security1.svg'
import security2 from '../../../images/security/security2.svg'
import security3 from '../../../images/security/security3.svg'
import security4 from '../../../images/security/security4.svg'
import security5 from '../../../images/security/security5.svg'
import security6 from '../../../images/security/security6.svg'
import { Helmet } from 'react-helmet'

const CyberSecurity = ({setActiveLink}) => {

  console.log('CyberSecurity');


  var horiCards = [
    {
      img: security2,
      title: "Threat Detection and Response",
      desp: "Conduct research and analyze risks using our state-of-the-art services. Our advanced tools and processes ensure that your digital assets remain secure against evolving cyber threats."
    },
    {
      img: security6,
      title: "Secure Cloud Solutions      ",
      desp: "Increase data security with NimbusBytes’ secure cloud solutions, including strong encryption and access control to protect valuable information. "
    },
    {
      img: security1,
      title: "Network Security Optimization",
      desp: "Strengthen your digital defense with superior NimbusBytes network security, analyzing, identifying, and repairing vulnerabilities for a secure business foundation."
    },

    {
      img: security3,
      title: "Endpoint Security",
      desp: "Secure every device with NimbusBytes’ comprehensive endpoint security, protecting against malware, unauthorized access, and potential breaches."
    },
    {
      img: security4,
      title: "Penetration Testing",
      desp: "Make sure your system is flexible with our penetration testing services. We simulate real-world cyberattacks to identify vulnerabilities and strengthen your defenses against potential breaches."
    },
    {
      img: security5,
      title: "Cybersecurity Consulting",
      desp: "Gain a competitive advantage with NimbusBytes’ cybersecurity advice. Our experts offer customized guidance to strengthen your growing digital strategy."
    },

  ]

  var serviceTabs = [
    {
      img: tab1,
      title: "Continuous Monitoring",
      desp: "Take advantage of NimbusBytes’ extensive knowledge and experience. Our dedicated professionals bring foolproof expertise in the dynamic field of Data & Analytics."
    },
    {
      img: tab2,
      title: "Threat Intelligence Analysis",
      desp: "Experience customized solutions tailored to your unique business needs. We understand that one size doesn’t fit all, and our services are designed to match your goals seamlessly."
    },
    {
      img: tab3,
      title: "Patch Management",
      desp: "Stay ahead of the latest technological developments. NimbusBytes combines state-of-the-art tools and information to ensure your business benefits from the most up-to-date Data & Analytics solutions."
    },
    {
      img: tab4,
      title: "Incident Response",
      desp: "Your success is our top priority. We focus on a customer-centric approach, working closely with each client to understand their challenges and aspirations and delivering solutions that move their business forward."
    },
    {
      img: tab5,
      title: "Vulnerability Assessment",
      desp: "Recognize and address vulnerabilities in your digital infrastructure with our thorough vulnerability assessment, strengthening your defenses against potential threats."
    },
    {
      img: tab6,
      title: "Compliance Policy",
      desp: "Adhere to regulatory requirements effortlessly with our comprehensive compliance solutions, ensuring your business complies with industry standards and regulations."
    },
  ]

  const migrateCards = [
    {
      title: "Proactive Defense",
      desp: "Continuous surveillance and rapid response to threats. Anticipate threats and ignore them.",
    },
    {
      title: "Custom Solutions",
      desp: "Customized cybersecurity services and solutions based on your unique needs. Open communication and mutual association. ",
    },
    {
      title: "Compliance expertise",
      desp: "Simplifying compliance with complex regulations. Mitigate compliance risks through comprehensive compliance management.",
    },
    {
      title: "Vulnerability Awareness",
      desp: "Become aware of vulnerabilities and address them quickly. Strong and up-to-date security regularly.",
    },
    {
      title: "Practical and Scalable",
      desp: "NimbusBytes is a leading cybersecurity company offering secure solutions for businesses of all sizes-agility to navigate the dynamic cybersecurity landscape.",
    },
    {
      title: "Relentless Commitment",
      desp: "Commitment to protecting your digital assets. Continuous improvement and innovation in cybersecurity strategies.",
    },
  ];

  const challengeCards = [
    {
      title: "Persistent Advanced Threats (APTs)",
      desp: "Businesses have been swallowed up by persistent and sophisticated cyber threats that require constant vigilance to detect and mitigate them.",
    },
    {
      title: "Data Breaches",
      desp: "Frequent data breaches carry significant risks, potentially resulting in financial loss, reputational damage, and legal consequences. ",
    },
    {
      title: "Internal Threats",
      desp: "Preventing internal security threats from employees or partners capable of intentionally or unintentionally compromising sensitive information is a significant challenge.",
    },
    {
      title: "Robust compliance",
      desp: "Managing and maintaining corporate compliance and compliance can be challenging, with changing requirements and potential legal consequences for non-compliance.",
    },
    {
      title: "Convenience management",
      desp: "Identifying and addressing vulnerabilities promptly is an ongoing challenge as new threats emerge and systems evolve.",
    },
    {
      title: "Resource limitation",
      desp: "Many companies need more budgets and expertise, making implementing and managing robust cybersecurity policies difficult.",
    },
    {
      title: "The Rapidly Evolving Threat Landscape",
      desp: "The dynamic nature of cyber threats requires constant adaptation to new attack sources, malware, and techniques used by cybercriminals.",
    },
  ];

  let stats = [
    { text: 'Reduce Cyber Threats by Up To', stats: '30%' },
    { text: 'Boost security efficiency with a ', stats: '25%' },
    { text: 'Achieve Faster Response to Potential Risks by', stats: '18%', styles: { marginBottom: 'auto' } },
    { text: 'Experience a Improvement in Overall Digital Resilience by', stats: '15%' },
  ]

  return (
    <div className='cyberSecurity'>

      <Helmet>
        <meta name='title' data-react-helmet="true" property="og:title" content="Robust Cybersecurity Services and Solutions | NimbusBytes" />
        <meta name='description' data-react-helmet="true" property="og:description" content="Protect your digital assets with our advanced Cybersecurity services. Stay ahead of threats and secure your business with cutting-edge solutions." />
      </Helmet>

      <div className="title__banner all-security">
        <h2 className='main'>  Cybersecurity <span className='redTitle'> Services</span>  </h2>
        <Breadcrum parentLink="/services" parentName="Services" childLink="/services/cybersecurity" childName="Cybersecurity Services"/>
      </div>

      <HeroBanner title="Security Redefined " redTitle="Robust Cybersecurity Services for Your Business" banner={banner}
        desp="Experience a new era of digital protection. At NimbusBytes, intelligence and expertise redefine cyber-immunity, ensuring your business meets security every time."
         stats={stats} setActiveLink={setActiveLink}
      />

      <SecurityCards title="NimbusBytes 
      " redTitle="Cybersecurity Services" lockBg={lockBg} setActiveLink={setActiveLink}
        securityCard={horiCards} desp="Explore our advanced cybersecurity services for unparalleled digital protection." />

      <HeroTabs imgBg={lockBg} title="Why Choose NimbusBytes for" redTitle="Cybersecurity Service" desp="Let NimbusBytes, the leading cybersecurity company take care of your cybersecurity. Our cybersecurity services experts work round the clock, providing proactive cybersecurity solutions and real-time threat response." horiTabs={serviceTabs} />

      <DataChallanges title="Cybersecurity  " redTitle="Challenges" challengeCards={challengeCards} desp="Analyze the cybersecurity landscape and uncover the most common challenges businesses face. NimbusBytes, the renowned cybersecurity company specializes in turning these challenges into opportunities, providing solutions designed for threat mitigation, robust compliance, and more. Choose us to navigate the digital ecosystem safely and efficiently." img1={chall1}
        img2={chall2} img3={chall3} capsule1Desp="Ensure Security Measure" capsule2Desp="Achieve High Retention Rate" />

      <MigrateCards headImg={securityImg} title="What Makes NimbusBytes the" firstImg={lockBg}
        redTitle="Right Choice for Cybersecurity Services" cardData={migrateCards} />

      <SimpleBanner title1="Book Your" redText="FREE" topDesp="Discuss Your Security Needs." link="/contactus" setActiveLink={setActiveLink}
        btnText="Let’s Talk" title="30-Minute Consultation." challengeCards={challengeCards} />

    </div>
  )
}

export default CyberSecurity