import React from 'react'
import './dataChallenge.css'
import Capsules from '../Capsules/Capsules';

const DataChallanges = ({ title, titleDesp, redTitle, challengeCards, img1, img2, img3, desp, capsule1Title,capsule1Desp, capsule2Title,capsule2Desp }) => {

    console.log(capsule1Desp);

    console.log("data challange");
    return (
        <div className="dataChallenge">
            <div className="container">


                <div className="data__container">
                    <div className="data__container_left">
                        <h3 className="main">
                            {title}
                            {titleDesp ? <span className="title__small">{titleDesp}</span> : ""}{" "}
                            {redTitle ? <span className="redTitle">{redTitle}</span> : ""}
                        </h3>
                        <p className='data_desp'>{desp}</p>
                        <div className="data__container_right mob">
                        <div className="data__img_left">
                            <img src={img1} alt="nimbusbytes" />
                        </div>
                        <div className="data__img_right">
                            <div className="capsule">
                                <div className="capsule__content">
                                    <h4 className='capsule__title'> {capsule1Title ? capsule1Title : ""} </h4>
                                    <p className='capsule__desp'> {capsule1Desp ? capsule1Desp : ""}</p>
                                </div>
                                <img className='img' src={img2} alt="nimbusbytes" />
                            </div>
                            <div className="capsule">
                                <img className='img' src={img3} alt="nimbusbytes" />
                                <div className="capsule__content">
                                    <h4 className='capsule__title'> {capsule2Title ? capsule2Title : ""}</h4>
                                    <p className='capsule__desp'>{capsule2Desp ? capsule2Desp : ""}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="data__card_container">
                            {
                                challengeCards.map((data, index) => (
                                    <div key={index} className="data__card">
                                        <span className='num'> {index < 9 && '0'}{index + 1}.</span>
                                        <div className="data__card_content">
                                            <h4 className="data__card_title">{data.title}</h4>
                                            {data.desp ? (<p className="data__card_title">{data.desp} </p>) : 
                                            (<ul>
                                                {data.list.map((ele, index)=> (
                                                    <li key={index}><span className='data__card_subdesp'>{ele.title} </span> <span>{ele.desp}</span></li>
                                                ))}
                                            </ul>)}
                                            
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    

                    <Capsules capsule2Title={capsule2Title} capsule1Title={capsule1Title} capsule1Desp={capsule1Desp} capsule2Desp={capsule2Desp} img1={img1} img2={img2} img3={img3} />
                </div>

            </div>
        </div>
    );
}

export default DataChallanges