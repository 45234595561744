import React from 'react'
import './ourClients.css'
import icon1 from '../../images/icons/aws-administrator.png'
import icon2 from '../../images/icons/aws-architect.png'
import icon3 from '../../images/icons/aws-associate.png'
import icon4 from '../../images/icons/aws-security.png'
import icon5 from '../../images/icons/google-cloud-certified.png'
import icon6 from '../../images/icons/icon.png'
import icon7 from '../../images/icons/microsoft-architect.png'
import Gradient from '../UI/Gradient'

const OurClients = () => {

    let icons = [icon1,icon2,icon3,icon4,icon5,icon6,icon7]

  return (
    <div className="ourClient_wrapper">
    <div className='ourClient'>
        <div className="ourClient__title_container">
            <span className='smallBubble'></span>
            <div className="ourClient__title">
                <h3>Our <span>Achievements</span></h3>
                <p>Empower innovation and enhance your process workflow performance with our high-quality IT services.</p>
            </div>
            <span className='smallBubble'></span>
        </div>
        <Gradient width={200} height={200} top="50%" left="50%" />
          <Gradient width={200} height={200} top="50%" left="50%"/>
        <div className="ourClient__Container">
            {
                icons.map((ele,index)=>(
                    <>
                    <div className="ourClient__icon" key={index}>
                        <img src={ele} alt="icon" />
                    </div>
                    <span className='smallBubble'></span>
                    </>
                ))
            }
        </div> 
        
        </div>
    </div>
  )
}

export default OurClients