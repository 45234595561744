import React, { useState } from 'react'
import { IconsGradient } from '../UI/Gradient'
import './contactUsForm.css'
import logo from '../../images/contactUs/logo.svg'

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({
    firstname: '',
    lastname: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check each input field for validity
    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        errors[key] = `Please enter your ${key === 'email' ? 'email address' : key}`;
      }
    });

    // Update formErrors state with error messages
    setFormErrors(errors);

    let button = document.querySelector('.contactus-btn')



    if (Object.keys(errors).length === 0) {
      fetch('https://formspree.io/f/mgegzkvv', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          button.classList.add('clicked');
          setTimeout(function () {
            button.classList.add('success');
          }, 300);


          setTimeout(function () {
            button.classList.remove('clicked');
            button.classList.remove('success');
          }, 1000);
        })
        .catch(error => {
          console.error('Form submission failed:', error);
          // Handle error, e.g., show error message to the user
        });
    }




  };


  // {
  //   "next": "/thanks?language=en",
  //   "ok": true
  // }
  return (
    <div className="contactUs__right">
      <form className="form" onSubmit={handleSubmit}>
        <div className="bubble-icon">
          <img src={logo} alt="logo" />
        </div>
        <h4>Free Digital Strategy
          Consultation</h4>
        <p>Tell us how we can support
          your
          transformation—complete
          the form below and let’s get
          started on crafting
          innovative solutions that
          meet your unique business
          needs.</p>
        <div className="inputs">
          <div className="input__wrapper">
            <input
              type="text"
              placeholder="First Name*"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
            />
            {formErrors.firstname && <span className="error">{formErrors.firstname}</span>}
          </div>
          <div className="input__wrapper">
            <input
              type="text"
              placeholder="Last Name*"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
            />
            {formErrors.lastname && <span className="error">{formErrors.lastname}</span>}
          </div>
        </div>
        <div className="inputs">
          <div className="input__wrapper">
            <input
              type="email"
              placeholder="Your Work Email*"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {formErrors.email && <span className="error">{formErrors.email}</span>}
          </div>
        </div>
        <div className="input__wrapper">
          <textarea
            placeholder="Tell Us How Can Help!"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
          {formErrors.message && <span className="error">{formErrors.message}</span>}
        </div>
        <button className="red-btn contactus-btn btn" type="submit"> <span></span> <span></span> <span></span> <span></span> 	
        
        <span className="text">
          Get Started
        </span>
          <span className="success-text">
            Submitted
          </span></button>
      </form>


      <IconsGradient right="-25%" top="0" rotate="210deg" />

    </div>


  )
}

export default ContactUsForm