import React from 'react'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import './migrateCards.css'
import { Link } from 'react-router-dom';
import { TiArrowForward } from 'react-icons/ti';

const MigrateCards = ({ title, titleDesp, redTitle, img, cardData, headImg, firstImg, secondImg,desp, iconImg }) => {

    console.log('migrate cards');
    return (
        <div className='migrate'>
           {firstImg ? <img src={firstImg} className='firstImg' alt="nimbusbytes" /> : ""}
            <div className="container">
                <div className="title-container">
                    <div className="title-text">
                        <h3 className='main'>{title}  {titleDesp ? <span className='title__small'>{titleDesp}</span> : ''} {redTitle ? <span className='redTitle'>{redTitle}</span> : ''}</h3>
                        {desp && (<p> {desp} </p>)}
                    </div>
                  <img src={headImg} alt="img" />
                </div>

                <div className="migrate__cards">
                    {
                        cardData.map((data, index) => (
                            <div key={index} className="migrate__card">
                                { data.link ? ( <div className='redirect'><TiArrowForward /></div> ) : "" }
                                {data.img ? <img src={data.img} alt="img" /> : <MdOutlineKeyboardArrowRight />}
                                <div className="migrate__content">
                                    {data.title ? <h4>{data.title}</h4> : ''}
                                    {data.desp ? <p>{data.desp}</p> : ''}
                                </div>
                                { data.link ?  <Link to={data.link} onClick={() => {window.scrollTo(0,0) }}/> : ""}
                            </div>
                        ))
                    }
                </div>
            </div>
            {secondImg ? <img src={secondImg} className='secondImg' alt="nimbusbytes" /> : ''}

        </div>
    )
}

export default MigrateCards