import React from 'react'
import './contactUsPage.css'
import HeroBanner from '../../components/HeroBanner/HeroBanner'
import OurClients from '../../components/OurClients/OurClients'
import Faqs from '../../components/Faqs/Faqs'
import SimpleBanner from '../../components/SimpleBanner/SimpleBanner'
import ContactUs from '../../components/ContactUs/ContactUs'
import Testimonial from '../../components/Testimonial/Testimonial'

const ContactUsPage = ({setActiveLink}) => {

  let faqMap = [
    {
        title:"What is Information Technology (IT) Service?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
        title:"What is a Cloud Computing Service?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
        title:"What is Cybersecurity Service?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
        title:"What is a Data and Analytics Service?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
        title:"How much does it cost to start with IT services?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
        title:"What is your pricing model?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
        title:"Which tools, technologies, and frameworks do you support?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
        title:"Who Is NimbusBytes?",
        des:"Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
]

const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  

  return (
    <div className='contactus-page'>
         <HeroBanner setActiveLink={setActiveLink} redBold="" title="Transform Your Business with Intelligent Digital Solutions "  redTitle="Unlock Your Business's Full Potential with Our Solutions"  
            desp="Ready to embark on your 
digital transformation 
journey? We provide 
tailored business 
intelligence and digital 
solutions to help you 
streamline operations, 
improve efficiency, and 
drive growth. Let's work 
together to bring your vision 
to life" 
            subDesp=""
            isContact={true}
            childLink="/contactus"
            childName="Contact Us"
        />

        <ContactUs isContact={true} scrollTop={scrollTop} />

        <OurClients />

        <Testimonial />

        <Faqs faqMap={faqMap} title="FAQ" desp="Empower innovation and enhance your process workflow performance with our high-quality IT services." />

       

        <SimpleBanner title1="Book Your" redText="FREE" topDesp="Discuss Your Needs." setActiveLink={setActiveLink}
        btnText="Let 's  Connect" title="30 Minute Consultation." scrollTop={scrollTop} />

        
    </div>
  )
}

export default ContactUsPage