import React from 'react'
import { GoStarFill } from "react-icons/go";
import { FaTrophy } from "react-icons/fa";
import Breadcrum from '../../components/Breadcrum/Breadcrum';
import './ourTeam.css'
import SimpleBanner from '../../components/SimpleBanner/SimpleBanner';
import Gradient, { IconsGradient } from '../../components/UI/Gradient';
import bubble from '../../images/bubble.svg'

import teamImg1 from '../../images/teams/teamImg1.png'
import teamImg2 from '../../images/teams/teamImg2.png'
import teamImg3 from '../../images/teams/teamImg3.png'
import teamImg4 from '../../images/teams/teamImg4.png'

const ObjectiveCard = ({ data }) => {
    return (
        <div className="obj__card">
            <div className="obj__icon">{data.icon}</div>
            <div className="obj__title">{data.title}</div>
        </div>
    )
}

const TeamCard = ({ data }) => {
    return (
        <div className="teamCard">
            <div className="teamContent">
                <img src={data.img} alt="nimbusbytes" />
            </div>
            <h4>{data.title}</h4>
            <span>{data.des}</span>
        </div>

    )
}

const OurTeam = ({setActiveLink}) => {

    const Objectives = [{
        title: "Industry Recognized",
        icon: <GoStarFill />
    }, {
        title: "Winning With Certified Experts",
        icon: <FaTrophy />
    }]

    const teamCards = [
        {
            title: "Gurpreet Singh",
            des: "Co-Founder & CTO",
            img: teamImg4
        },
        // {
        //     title: "Deepak Salwan ",
        //     des: "Co-Founder & CEO",
        //     img: teamImg3
        // },
        {
            title: "Harvinder Singh",
            des: " Director",
            img: teamImg2
        },
        // {
        //     title: "Parshotam Kumar",
        //     des: " Director",
        //     img: teamImg1
        // },
    ]

    return (
        <div className='our-team'>

            <div className="title__banner">
                <h2 className='main'>  Meet The <span className='redTitle'> Team</span></h2>
                <Breadcrum parentLink="/" parentName="Home" childLink="/our-team" setActiveLink={setActiveLink} childName="Meet The Team" />
            </div>

            <div className="team__objective">
                <div className="team__objective-container">
                    <div className="team__objective-left">
                        <h4>OUR LEADERSHIP TEAM</h4>
                        <p>We're a growing team of tech enthusiasts, each with a unique approach and extensive years of experience. We're makers, thinkers, explorers, and solvers. We're curious and dedicated to creation and innovation that connect with global leaders like you.</p>
                        <Gradient width={200} height={200} top={400} left="0%" />
                    </div>
                    <div className="team__objective-right">
                        {
                            Objectives.map((data, index) => <ObjectiveCard key={index} data={data} />)
                        }
                         <IconsGradient right="10%" top="0" rotate="210deg" />
                    </div>
                </div>
            </div>

            <div className="team__cards">
                <div className="team__cards_container">
                    {
                        teamCards.map((data, index) => <TeamCard key={index} data={data} />)
                    }
                </div>
                <img src={bubble} className='bubble' alt="nimbusbytes" />
            </div>

            <SimpleBanner title1="We Would Love To" redText="Hear From You!" topDesp="Discuss Your Needs"
                btnText="Get In Touch" link="/contactus" btnText2="Our Expertise" link2="/services" setActiveLink={setActiveLink} />

        </div>
    )
}

export default OurTeam