import React from 'react'
import './software.css'
import banner from '../../../images/security-circle.png'
import HeroBanner from '../../../components/HeroBanner/HeroBanner'
import HoriBanner from '../../../components/HoriBanner/HoriBanner'
import HeroTabs from '../../../components/HeroTabs/HeroTabs'
import SimpleBanner from '../../../components/SimpleBanner/SimpleBanner'
import bubble from '../../../images/bubble.svg'
import MigrateCards from '../../../components/MigrateCards/MigrateCards'
import migrateAnal1 from '../../../images/migrate-anal-1.svg'

import DataChallanges from '../../../components/DataChallenges/DataChallanges'
import Breadcrum from '../../../components/Breadcrum/Breadcrum'

import horiIcon1 from '../../../images/software/horiIcon1.svg'
import horiIcon2 from '../../../images/software/horiIcon2.svg'
import horiIcon3 from '../../../images/software/horiIcon3.svg'
import horiIcon4 from '../../../images/software/horiIcon4.svg'
import horiIcon5 from '../../../images/software/horiIcon5.svg'
import horiIcon6 from '../../../images/software/horiIcon6.svg'
import migrateComp from '../../../images/software/migrateComp.svg'
import dataChallengeImg from '../../../images/software/dataChallengeImg.png'

import tabImg1 from '../../../images/software/tabImg1.svg'
import tabImg2 from '../../../images/software/tabImg2.svg'
import tabImg3 from '../../../images/software/tabImg3.svg'

import chall2 from '../../../images/challengeIcon2.svg'
import chall1 from '../../../images/security2.svg'
import { Helmet } from 'react-helmet'

import headerImg1 from '../../../images/software/softwareHeaser1.svg'
import headerImg2 from '../../../images/software/softwareHeaser2.svg'

const Software = ({setActiveLink}) => {
    var horiCards = [
        {
            img: horiIcon1,
            title: "Custom Software Development",
            desp: "Customized solutions development specifically to meet your business needs. Our software development experts bring your vision to life from idea to deployment.            "
        },
        {
            img: horiIcon2,
            title: "Mobile App Development",
            desp: "Reach your audience on the go with attractive mobile applications. We specialize in developing seamless, user-friendly mobile experiences across platforms.            "
        },
        {
            img: horiIcon3,
            title: "Web Application Development",
            desp: "Develop robust web applications that drive online success. Our dedicated software developers have extensive experience employing various technologies for dynamic and scalable web solutions.            "
        },

        {
            img: horiIcon4,
            title: "E-commerce Solutions",
            desp: "Boost your online presence with tailored e-commerce platforms. From user-friendly interfaces to secure payment gateways, we provide end-to-end solutions."
        },
        {
            img: horiIcon5,
            title: "Cloud-Based Solutions ",
            desp: "Leverage the power of the cloud for flexibility and scalability. Our cloud-based solutions ensure your applications are accessible, secure, and future-ready."
        },
        {
            img: horiIcon6,
            title: "Software Integration Services",
            desp: "Seamlessly connect your systems and applications for enhanced efficiency. We specialize in integrating diverse technologies to streamline your business processes."
        },

    ]

    const challengeCards = [
        {
            title: 'Discovery Phase',
            desp: "Let's analyze your business needs together. Figure out what matters most and set the approach for development aligned with your goals."      
        },
        {
            title: 'Roadmap Creation            ',
            desp: 'Plan your software journey with precise planning. Segregate into attainable steps and milestones that match your business vision.'
        }, {
            title: 'Agile Development',
            desp: "We adapt and build in small, flexible steps.  Regularly check in, adjust plans as needed, and ensure we're always moving forward."
        },
        {
            title: 'Communication Hub            ',
            desp: 'Stay connected throughout the journey. Regular updates and collaboration ensure your insights guide the development process.'
        },
        {
            title: 'Quality Assurance           ',
            desp: 'We put your software through rigorous testing. Fixing issues ensures a reliable, error-free, high-performing result.'
        },
        {
            title: 'Scalable Solutions           ',
            desp: 'Build a solution that evolves with your business. Design it to manage more users, features, and changes over time.'
        },
        {
            title: ' User-Friendly Design',
            desp: 'Make your software easy and enjoyable to use. Create interfaces that everyone can use, making your business accessible and engaging.      '
        },
        {
            title: 'Continuous Improvement    ',
            desp: 'Keep your software updated and efficient. Listen to feedback, provide continuous support, and make advanced updates for a solution that aligns with your needs.'
        }
    ]

    var serviceTabs = [
        {
            img: tabImg1,
            title: "IT Security ",
            desp: "Are you worried about data breaches and cybersecurity threats? How we handle it: At NimbusBytes, we make security a priority. We have robust procedures to protect your data during software development and ensure a secure environment for your software."
        },
        {
            img: tabImg2,
            title: "Delay without quality",
            desp: "Need help to balance speed and software quality? How we handle it: We get it - quality and timing matter. Our agile approach allows us to maintain high standards without compromising speed, delivering high-quality products when you need them."
        },
        {
            img: tabImg3,
            title: "Stress-Free Scaling",
            desp: "Planning for future sustainable growth? How we handle it: At NimbusBytes, scalability is built into the DNA of our solution. Your software will grow with your business, ensuring a seamless expansion without any headaches. "
        }
    ]

    let stats = [
        { text: 'Reduce Release Time by ', stats: '75%' },
        { text: 'Cost Savings of up to', stats: '20%', styles: { marginTop: "auto" } },
        { text: 'Boosted Efficiency by', stats: '30%' },
        { text: 'Faster time-to-market by ', stats: '50%' },
        { text: 'Zero server defects in production', stats: '', styles: { marginBottom: "auto" } },
        { text: 'Scalability for ', stats: '3x Growth', styles: { marginBottom: "auto" } },
    ]

    const migrateCards = [
        {
            desp: "We design an effective testing program based on your needs and customize the testing process accordingly.             ",
        },
        {
            desp: "We are device-agnostic and use various tools to improve your mobile app testing and usage experience.             ",
        },
        {
            desp: "Our mobile application QA services are tailored to each client’s specific needs and requirements." 
        },
        {
            desp: "We provide mobile app testing services across all verticals.             ",
        },
        {
            desp: "Our QA engineers have hands-on experience in a variety of industries. We use the latest equipment and technology."
        }
    ]

    console.log('software');


    return (
        <div className='software'>

            <Helmet>
                <meta name='title' data-react-helmet="true" property="og:title" content="Get Software Development Services and Solutions | NimbusBytes" />
                <meta name='description' data-react-helmet="true" property="og:description" content="Transform your ideas into reality with our Software Development services. Tailored solutions for scalable, efficient, and robust software product." />
            </Helmet>

            <div className="title__banner all-clouds">
                <img src={headerImg1} className='softwareHeaderImg1' alt="nimbusbytes" />
                <h2 className='main'>Software Development <span className='title__small'>Services</span></h2>
                <Breadcrum parentLink="/services" parentName="Services" childLink="/services/software-services" childName="Software Development Services"/>
                <img src={headerImg2} className='softwareHeaderImg2' alt="nimbusbytes" />
            </div>

            <HeroBanner title="Your Journey to  " titleDesp="Software Development Services Starts Here!" banner={banner} stats={stats}
               btnText="Accelerate Your Business" desp="NimbusBytes offers comprehensive and customized software development services tailored to your needs. With us, you can achieve" setActiveLink={setActiveLink}
            />

            <DataChallanges title="Our Software Development Services " redTitle="Approach" desp="NimbusBytes provides a roadmap to develop software that meets your business needs, making technology solutions easy to use for you. Our software development services approach consists of the following process" challengeCards={challengeCards} img1={dataChallengeImg}
                            img2={chall1} img3={chall2} capsule1Desp="Secure Development Approach"
                            capsule2Desp="Achieve High Retention Rate" />

            <HoriBanner title="NimbusBytes" imgBg={migrateAnal1}
                horiCard={horiCards}
                titleDesp=" Software Development Services " desp="" />

            <HeroTabs imgBg={bubble} title="Software Development Services" redTitle="Challenges" desp="" horiTabs={serviceTabs} />

            <MigrateCards headImg={migrateComp} firstImg={migrateAnal1} title="What makes us " redTitle="different from others? " cardData={migrateCards} />
            
            <SimpleBanner title1="Book Your 30-Minute" redText="FREE" topDesp="Innovation Awaits!" link="/contactus" setActiveLink={setActiveLink}
                btnText="Let’s Talk" title=" Consultation Now." />
            
        </div>
    )
}

export default Software